// getSignedUrlHelper.js
import { generatePreSignedUrl } from "../api/userApi";

export const generateSignedUrl = async (
  fileName,
  folder = "meal-pictures",
  isUploading
) => {
  try {
    const { signedUrl } = await generatePreSignedUrl({
      filename: fileName,
      folder,
      isUploading
    });
    return signedUrl;
  } catch (error) {
    console.error("Error generating signed URL:", error);
    throw new Error("Failed to generate signed URL");
  }
};
