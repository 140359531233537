import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function CountrySelect({ setValue, setCapabilityValue }) {
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: "100%", mb: 5 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>
        );
      }}
      defaultValue={{
        code: "US",
        label: "United States",
        phone: "1",
        capabilities: ["transfers", "card_payments"],
        suggested: true
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          console.log("Code:", newValue.code);
        }
        setValue(newValue?.code); // If using state to store selected value
        setCapabilityValue(newValue?.capabilities);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: "new-password" // disable autocomplete and autofill
            }
          }}
        />
      )}
    />
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "AL",
    label: "Albania",
    phone: "355",
    capabilities: ["transfers"]
  },
  {
    code: "AM",
    label: "Armenia",
    phone: "374",
    capabilities: ["transfers"]
  },
  {
    code: "AO",
    label: "Angola",
    phone: "244",
    capabilities: ["transfers"]
  },
  {
    code: "AR",
    label: "Argentina",
    phone: "54",
    capabilities: ["transfers"]
  },
  {
    code: "AT",
    label: "Austria",
    phone: "43",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "AZ",
    label: "Azerbaijan",
    phone: "994",
    capabilities: ["transfers"]
  },
  {
    code: "BD",
    label: "Bangladesh",
    phone: "880",
    capabilities: ["transfers"]
  },
  {
    code: "BE",
    label: "Belgium",
    phone: "32",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "BG",
    label: "Bulgaria",
    phone: "359",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    capabilities: ["transfers"]
  },
  {
    code: "BJ",
    label: "Benin",
    phone: "229",
    capabilities: ["transfers"]
  },
  {
    code: "BO",
    label: "Bolivia",
    phone: "591",
    capabilities: ["transfers"]
  },
  {
    code: "BS",
    label: "Bahamas",
    phone: "1-242",
    capabilities: ["transfers"]
  },
  {
    code: "BT",
    label: "Bhutan",
    phone: "975",
    capabilities: ["transfers"]
  },
  {
    code: "BW",
    label: "Botswana",
    phone: "267",
    capabilities: ["transfers"]
  },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "CH",
    label: "Switzerland",
    phone: "41",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "CL",
    label: "Chile",
    phone: "56",
    capabilities: ["transfers"]
  },
  {
    code: "CO",
    label: "Colombia",
    phone: "57",
    capabilities: ["transfers"]
  },
  {
    code: "CR",
    label: "Costa Rica",
    phone: "506",
    capabilities: ["transfers"]
  },
  {
    code: "CY",
    label: "Cyprus",
    phone: "357",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "CZ",
    label: "Czech Republic",
    phone: "420",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "DK",
    label: "Denmark",
    phone: "45",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
    capabilities: ["transfers"]
  },
  {
    code: "DZ",
    label: "Algeria",
    phone: "213",
    capabilities: ["transfers"]
  },
  {
    code: "EC",
    label: "Ecuador",
    phone: "593",
    capabilities: ["transfers"]
  },
  {
    code: "EE",
    label: "Estonia",
    phone: "372",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "EG",
    label: "Egypt",
    phone: "20",
    capabilities: ["transfers"]
  },
  {
    code: "ES",
    label: "Spain",
    phone: "34",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "ET",
    label: "Ethiopia",
    phone: "251",
    capabilities: ["transfers"]
  },
  {
    code: "FI",
    label: "Finland",
    phone: "358",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "GA",
    label: "Gabon",
    phone: "241",
    capabilities: ["transfers"]
  },
  {
    code: "GB",
    label: "United Kingdom",
    phone: "44",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "GH",
    label: "Ghana",
    phone: "233",
    capabilities: ["transfers"]
  },
  {
    code: "GI",
    label: "Gibraltar",
    phone: "350",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "GM",
    label: "Gambia",
    phone: "220",
    capabilities: ["transfers"]
  },
  {
    code: "GR",
    label: "Greece",
    phone: "30",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "GT",
    label: "Guatemala",
    phone: "502",
    capabilities: ["transfers"]
  },
  {
    code: "GY",
    label: "Guyana",
    phone: "592",
    capabilities: ["transfers"]
  },
  {
    code: "HR",
    label: "Croatia",
    phone: "385",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "HU",
    label: "Hungary",
    phone: "36",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "ID",
    label: "Indonesia",
    phone: "62",
    capabilities: ["transfers"]
  },
  {
    code: "IE",
    label: "Ireland",
    phone: "353",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "IL",
    label: "Israel",
    phone: "972",
    capabilities: ["transfers"]
  },
  {
    code: "IN",
    label: "India",
    phone: "91",
    capabilities: ["transfers"]
  },
  {
    code: "IS",
    label: "Iceland",
    phone: "354",
    capabilities: ["transfers"]
  },
  {
    code: "IT",
    label: "Italy",
    phone: "39",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "JM",
    label: "Jamaica",
    phone: "1-876",
    capabilities: ["transfers"]
  },
  {
    code: "JO",
    label: "Jordan",
    phone: "962",
    capabilities: ["transfers"]
  },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "KE",
    label: "Kenya",
    phone: "254",
    capabilities: ["transfers"]
  },
  {
    code: "KH",
    label: "Cambodia",
    phone: "855",
    capabilities: ["transfers"]
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    capabilities: ["transfers"]
  },
  {
    code: "KZ",
    label: "Kazakhstan",
    phone: "7",
    capabilities: ["transfers"]
  },
  {
    code: "LI",
    label: "Liechtenstein",
    phone: "423",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "LK",
    label: "Sri Lanka",
    phone: "94",
    capabilities: ["transfers"]
  },
  {
    code: "LT",
    label: "Lithuania",
    phone: "370",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "LU",
    label: "Luxembourg",
    phone: "352",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "LV",
    label: "Latvia",
    phone: "371",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "MA",
    label: "Morocco",
    phone: "212",
    capabilities: ["transfers"]
  },
  {
    code: "MC",
    label: "Monaco",
    phone: "377",
    capabilities: ["transfers"]
  },
  {
    code: "MG",
    label: "Madagascar",
    phone: "261",
    capabilities: ["transfers"]
  },
  {
    code: "MN",
    label: "Mongolia",
    phone: "976",
    capabilities: ["transfers"]
  },
  {
    code: "MT",
    label: "Malta",
    phone: "356",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "MU",
    label: "Mauritius",
    phone: "230",
    capabilities: ["transfers"]
  },
  {
    code: "MX",
    label: "Mexico",
    phone: "52",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "MY",
    label: "Malaysia",
    phone: "60",
    capabilities: ["transfers"]
  },
  {
    code: "MZ",
    label: "Mozambique",
    phone: "258",
    capabilities: ["transfers"]
  },
  {
    code: "NA",
    label: "Namibia",
    phone: "264",
    capabilities: ["transfers"]
  },
  {
    code: "NE",
    label: "Niger",
    phone: "227",
    capabilities: ["transfers"]
  },
  {
    code: "NG",
    label: "Nigeria",
    phone: "234",
    capabilities: ["transfers"]
  },
  {
    code: "NL",
    label: "Netherlands",
    phone: "31",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "NO",
    label: "Norway",
    phone: "47",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "NZ",
    label: "New Zealand",
    phone: "64",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "OM",
    label: "Oman",
    phone: "968",
    capabilities: ["transfers"]
  },
  {
    code: "PA",
    label: "Panama",
    phone: "507",
    capabilities: ["transfers"]
  },
  {
    code: "PE",
    label: "Peru",
    phone: "51",
    capabilities: ["transfers"]
  },
  {
    code: "PH",
    label: "Philippines",
    phone: "63",
    capabilities: ["transfers"]
  },
  {
    code: "PK",
    label: "Pakistan",
    phone: "92",
    capabilities: ["transfers"]
  },
  {
    code: "PL",
    label: "Poland",
    phone: "48",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "PT",
    label: "Portugal",
    phone: "351",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "PY",
    label: "Paraguay",
    phone: "595",
    capabilities: ["transfers"]
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    capabilities: ["transfers"]
  },
  {
    code: "RO",
    label: "Romania",
    phone: "40",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "RS",
    label: "Serbia",
    phone: "381",
    capabilities: ["transfers"]
  },
  {
    code: "RW",
    label: "Rwanda",
    phone: "250",
    capabilities: ["transfers"]
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    capabilities: ["transfers"]
  },
  {
    code: "SE",
    label: "Sweden",
    phone: "46",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "SG",
    label: "Singapore",
    phone: "65",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "SI",
    label: "Slovenia",
    phone: "386",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "SK",
    label: "Slovakia",
    phone: "421",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "SM",
    label: "San Marino",
    phone: "378",
    capabilities: ["transfers"]
  },
  {
    code: "SN",
    label: "Senegal",
    phone: "221",
    capabilities: ["transfers"]
  },
  {
    code: "SV",
    label: "El Salvador",
    phone: "503",
    capabilities: ["transfers"]
  },
  {
    code: "TH",
    label: "Thailand",
    phone: "66",
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "TN",
    label: "Tunisia",
    phone: "216",
    capabilities: ["transfers"]
  },
  {
    code: "TR",
    label: "Turkey",
    phone: "90",
    capabilities: ["transfers"]
  },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
    capabilities: ["transfers"]
  },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
    capabilities: ["transfers", "card_payments"]
  },
  {
    code: "UY",
    label: "Uruguay",
    phone: "598",
    capabilities: ["transfers"]
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
    capabilities: ["transfers"]
  },
  {
    code: "VN",
    label: "Vietnam",
    phone: "84",
    capabilities: ["transfers"]
  },
  {
    code: "ZA",
    label: "South Africa",
    phone: "27",
    capabilities: ["transfers"]
  }
];
