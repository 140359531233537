export const selectIsAuthenticated = (state) => state.adminauth.isAuthenticated;
export const selectCurrentSuperAdmin = (state) => state.adminauth.admin;
export const selectCurrentFreeUser = (state) => state.adminauth.freeUserNeeded;
export const selectCurrentSuperAdminFilterData = (state) =>
  state.adminauth.filterData;
export const selectAuthError = (state) => state.adminauth.error;
export const selectAuthLoading = (state) => state.adminauth.loading;
export const selectDashboardData = (state) => state.adminauth.dashboardData;
export const selectSubAdminBillingDetailsdData = (state) =>
  state.adminauth.subAdminBillingDetails;
export const selectCurrentUserCards = (state) => state.adminauth.cards;
