import { generatePreSignedUrl } from "../api/userApi";

// Function to compress image before upload
const compressImage = async (file, maxWidthHeight = 1200, quality = 0.7) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        // Create canvas
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Resize if needed
        if (width > height && width > maxWidthHeight) {
          height = Math.round((height * maxWidthHeight) / width);
          width = maxWidthHeight;
        } else if (height > maxWidthHeight) {
          width = Math.round((width * maxWidthHeight) / height);
          height = maxWidthHeight;
        }

        canvas.width = width;
        canvas.height = height;

        // Draw and compress
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Get the compressed data as blob
        canvas.toBlob(
          (blob) => {
            // Create a new file from the blob
            const compressedFile = new File([blob], file.name, {
              type: "image/jpeg",
              lastModified: Date.now()
            });

            resolve(compressedFile);
          },
          "image/jpeg",
          quality
        );
      };
    };
  });
};

export const uploadFile = async (file, filename, folder = "meal-pictures") => {
  if (!file) {
    throw new Error("No file provided");
  }

  // Default compression options
  const compressionOptions = {
    compress: true,
    maxWidthHeight: 1200,
    quality: 0.7
  };

  let fileToUpload = file;

  // Only compress if it's an image and compression is enabled
  if (compressionOptions.compress && file.type.startsWith("image/")) {
    fileToUpload = await compressImage(
      file,
      compressionOptions.maxWidthHeight,
      compressionOptions.quality
    );
    console.log(
      `Image compressed from ${file.size} to ${fileToUpload.size} bytes`
    );
  }

  try {
    const { signedUrl } = await generatePreSignedUrl({
      filename,
      fileType: fileToUpload.type,
      folder,
      isUploading: true
    });
    await fetch(signedUrl, {
      method: "PUT",
      body: fileToUpload,
      headers: { "Content-Type": fileToUpload.type }
    });
    // await s3Client.send(new PutObjectCommand(params));
    return "File uploaded successfully!";
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("File upload failed");
  }
};
