import {
  Alert,
  Box,
  Popover,
  FormControl,
  MenuItem,
  Select,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { ReactComponent as ImportIcon } from "../../assets/import.svg";
import { ReactComponent as ExportIcon } from "../../assets/export.svg";
import UsersTab from "../SubTabs/Users";
import AddUserModal from "../Modals/AddUser";
import OnlyUserTab from "./OnlyUsers";
import { useCSVReader } from "react-papaparse";
import { addBulkUserCsv, exportUserCsv } from "../../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSuperAdmin,
  selectCurrentSuperAdminFilterData
} from "../../features/auth/authSelectors";
import {
  fetchAllUsers,
  fetchUsersByType
} from "../../features/users/userSlice";
import dayjs from "dayjs";

function validateEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const Users = ({ isAdmin = true }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [editData, setEditData] = React.useState(null);
  const { CSVReader } = useCSVReader();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const adminData = useSelector(selectCurrentSuperAdmin);
  const dispatch = useDispatch();
  const filterData = useSelector(selectCurrentSuperAdminFilterData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    trainingLevel: "",
    gym: "",
    coach: "",
    company: "",
    affiliate: ""
  });
  const [applyFilter, setApplyFilter] = useState(false);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => {
      const updatedFilters = {
        ...prev,
        [name]: value
      };

      if (name !== "trainingLevel") {
        updatedFilters.gym = name === "gym" ? value : "";
        updatedFilters.coach = name === "coach" ? value : "";
        updatedFilters.company = name === "company" ? value : "";
        updatedFilters.affiliate = name === "affiliate" ? value : "";
      }

      return updatedFilters;
    });
  };
  return (
    <Box>
      <Box display="flex" maxHeight="48px" justifyContent="space-between">
        <Box display="flex" gap={2}>
          <TextField
            variant="outlined" // You can change this to "filled" or "standard" as needed
            placeholder="Search...(at least 4 characters to get results)"
            size="small" // Adjust size if needed
            fullWidth // Makes the search bar take full width
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "17px" }} />
                  </InputAdornment>
                )
              }
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: "14px 11px",
                height: "100%",
                //   width: "387px",
                borderRadius: 2
              },
              width: "387px",
              fontSize: "11px",
              background: "#FFFFFF", // Optional: set background color
              borderRadius: 2 // Optional: round corners
            }}
          />
          {isAdmin ? (
            <Button
              sx={{
                fontSize: "14px",
                padding: "16px 24px",
                border: "1px solid #6B7280",
                color: "#6B7280",
                fontWeight: 700,
                textTransform: "none"
              }}
              onClick={handleOpen}
              aria-describedby={id}
              variant="outlined"
              disableRipple
              startIcon={<FilterIcon />}
            >
              Filter
            </Button>
          ) : (
            <></>
          )}
        </Box>
        <Box display="flex" gap={2}>
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              border: "1px solid #1976d2",
              color: "#1976d2",
              fontWeight: 700,
              textTransform: "none"
            }}
            onClick={async () => {
              await exportUserCsv(dayjs().format("YYYY-MM-DD"));
            }}
            variant="outlined"
            startIcon={<ExportIcon />}
          >
            Export
          </Button>
          {
            // isAdmin ? (
            //   <Button
            //     sx={{
            //       fontSize: "14px",
            //       padding: "16px 24px",
            //       border: "1px solid #1976d2",
            //       color: "#1976d2",
            //       fontWeight: 700,
            //       textTransform: "none"
            //     }}
            //     variant="outlined"
            //     startIcon={<ImportIcon />}
            //   >
            //     Import
            //   </Button>
            // ) :
            adminData?.userType === "Affiliate" ? (
              <></>
            ) : (
              <CSVReader
                onUploadAccepted={async (results) => {
                  // Extract header row and validate
                  const [headerRow, ...dataRows] = results.data;

                  // Normalize headers to lowercase for validation
                  const normalizedHeaders = headerRow.map((header) =>
                    header.trim().toLowerCase()
                  );
                  const expectedHeaders = ["name", "email"];

                  // Check if normalized headers are exactly ["name", "email"]
                  if (
                    normalizedHeaders.length !== expectedHeaders.length ||
                    !expectedHeaders.every(
                      (header, index) => header === normalizedHeaders[index]
                    )
                  ) {
                    setSnackbarMessage(
                      "Invalid CSV format. The file must have only 'name' and 'email' as headers."
                    );
                    setOpenSnackbar(true);
                    return;
                  }

                  // Optional: Validate the rows for correct data (non-empty, valid email format, etc.)
                  const invalidRows = dataRows.filter(
                    (row) => row.length !== 2 || !validateEmail(row[1])
                  );

                  if (invalidRows.length > 0) {
                    setSnackbarMessage(
                      "Some rows have invalid data. Please check your CSV."
                    );
                    setOpenSnackbar(true);
                    return;
                  }

                  setSnackbarMessage(
                    "CSV is valid. Shortly, you will see the users listed below."
                  );
                  setOpenSnackbar(true);
                  const response = await addBulkUserCsv({
                    allData: dataRows.map((item) => ({
                      fullName: item[0],
                      email: item[1],
                      password: "Temp@123",
                      referenceId: adminData?.referenceId,
                      userType: adminData?.userType
                    }))
                  });
                  if (response.addedCount) {
                    dispatch(
                      isAdmin
                        ? fetchAllUsers({
                            page: 0,
                            date: dayjs().format("YYYY-MM-DD"),
                            limit: 10,
                            searchValue: ""
                          })
                        : fetchUsersByType({
                            page: 0,
                            limit: 10,
                            searchValue: "",
                            date: dayjs().format("YYYY-MM-DD")
                          })
                    );
                  }
                }}
              >
                {({ getRootProps }) => (
                  <Tooltip
                    title={
                      "Ensure your CSV has 'name' and 'email' columns only. After import, users will be created automatically with temp password 'Temp@123'."
                    }
                  >
                    <Button
                      sx={{
                        fontSize: "14px",
                        padding: "11px 24px",
                        border: "1px solid #1976d2",
                        color: "#1976d2",
                        fontWeight: 700,
                        textTransform: "none",
                        ":disabled": {
                          backgroundColor: "#A7C8E9",
                          color: "#FFF",
                          pointerEvents: "none"
                        }
                      }}
                      disabled={
                        !isAdmin &&
                        !adminData?.referenceData?.connectAccountLink &&
                        adminData?.userType !== "Company"
                      }
                      {...getRootProps()}
                      variant="outlined"
                      startIcon={<ImportIcon />}
                    >
                      Import
                    </Button>
                  </Tooltip>
                )}
              </CSVReader>
            )
          }

          <Button
            disabled={
              !adminData?.referenceData?.connectAccountLink &&
              adminData?.userType !== "Company" &&
              !isAdmin
            }
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              background: "#1976d2",
              border: "1px solid #1976d2",
              color: "#fff",
              fontWeight: 700,
              textTransform: "none",
              ":disabled": {
                backgroundColor: "#A7C8E9",
                color: "#FFF",
                pointerEvents: "none"
              }
            }}
            onClick={() => setOpenModal(true)}
            variant="outlined"
          >
            Add User
          </Button>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: 120 }}>Training Level</Typography>
            <FormControl fullWidth>
              <Select
                name="trainingLevel"
                value={filters.trainingLevel}
                onChange={handleChange}
              >
                {filterData?.trainingLevels?.map((item) => (
                  <MenuItem value={item._id}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: 120 }}>By Gym</Typography>
            <FormControl fullWidth>
              <Select name="gym" value={filters.gym} onChange={handleChange}>
                {filterData?.typeList?.gym?.map((item) => (
                  <MenuItem value={item._id}>{item.gymName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: 120 }}>By Coach</Typography>
            <FormControl fullWidth>
              <Select
                name="coach"
                value={filters.coach}
                onChange={handleChange}
              >
                {filterData?.typeList?.coach?.map((item) => (
                  <MenuItem value={item._id}>{item.coachName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: 120 }}>By Company</Typography>
            <FormControl fullWidth>
              <Select
                name="company"
                value={filters.company}
                onChange={handleChange}
              >
                {filterData?.typeList?.company?.map((item) => (
                  <MenuItem value={item._id}>{item.companyName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: 120 }}>By Affiliate</Typography>
            <FormControl fullWidth>
              <Select
                name="affiliate"
                value={filters.affiliate}
                onChange={handleChange}
              >
                {filterData?.typeList?.affiliate?.map((item) => (
                  <MenuItem value={item._id}>{item.affiliateName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Button
            variant="contained"
            sx={{ background: "#1976d2", textTransform: "none" }}
            onClick={() => {
              setApplyFilter(true);
              handleClose();
            }}
          >
            Apply Filters
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1976d2",
              borderColor: "#1976d2",
              textTransform: "none"
            }}
            onClick={() => {
              setFilters({});
              setApplyFilter(true);
              handleClose();
            }}
          >
            Clear Filters
          </Button>
        </Box>
      </Popover>
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage?.includes("is valid") ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <AddUserModal
        editData={editData}
        open={openModal}
        isAdmin={isAdmin}
        onClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
      />
      {isAdmin ? (
        <UsersTab
          isAdmin={isAdmin}
          applyFilter={applyFilter}
          setApplyFilter={setApplyFilter}
          filters={filters}
          searchValue={searchValue}
          onEdit={(data) => {
            setOpenModal(true);
            setEditData(data);
          }}
          openModal={openModal}
        />
      ) : (
        <OnlyUserTab
          onEdit={(data) => {
            setOpenModal(true);
            setEditData(data);
          }}
          searchValue={searchValue}
          openModal={openModal}
        />
      )}
    </Box>
  );
};

export default Users;
