import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSuperAdmin,
  selectIsAuthenticated
} from "../../features/auth/authSelectors";
import { getCurrentUser } from "../../features/auth/authSlice";
import { FailedPaymentModal } from "../Modals/FailedPaymentModal";

const PrivateRoute = ({ children, setIsFailedPayment, isFailedPayment }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentSuperAdmin);
  // const expirationTime = localStorage.getItem("expirationTime");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.isFailedPayment) {
      setIsFailedPayment(true);
    }
  }, [currentUser]);

  if (!isAuthenticated && !localStorage.getItem("adminToken")) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {isFailedPayment && (
        <FailedPaymentModal
          open={isFailedPayment}
          onClose={() => setIsFailedPayment(false)}
        />
      )}
      {children}
    </>
  );

  return children;
};

export default PrivateRoute;
