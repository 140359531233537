import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { forgetPasswordUser } from "../../features/auth/authSlice";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Box,
  styled,
  Alert
} from "@mui/material";
import useThemeColor from "../../utils/useTheme";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0, 3, 0)
}));

const StyledInputLabel = styled("label")(({ theme, styledColors }) => ({
  position: "absolute",
  top: "-12px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors?.color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: styledColors.lightBlack
      // borderColor: styledColors.background
    },
    "&:hover fieldset": {
      // borderColor: styledColors?.color
    },
    "&.Mui-focused fieldset": {
      color: styledColors.lightBlack
      // borderColor: styledColors?.color
    }
  }
}));

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: ""
  });

  const [formErrors, setFormErrors] = useState({
    email: false
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    const { payload } = await dispatch(forgetPasswordUser(formData));
    if (!payload.error) {
      setSnackbarMessage("Success! Check your email for a reset link.");
      setOpenSnackbar(true);
      setTimeout(() => navigate("/login"), 3000);
    } else {
      setSnackbarMessage(payload.error || "Forgot password failed.");
      setOpenSnackbar(true);
    }
  };

  const handleEmailValidation = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    }
  };

  useEffect(() => {
    const scrollableElement = document.documentElement; // or document.body
    scrollableElement.scrollTo(0, 0);
  }, []);
  const styles = useThemeColor();
  return (
    <Box
      color={styles.color}
      backgroundColor={styles.background}
      display={"flex"}
      width="100%"
      overflow="hidden"
      height="100dvh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.jpg"
          alt="base"
          sx={{
            width: "100%",
            height: "100dvh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </Box>

      <Box
        flexGrow={1}
        paddingBottom={4}
        paddingLeft={10}
        sx={{
          paddingRight: { xs: 5, md: 13, lg: 30 },
          paddingLeft: { xs: 5, md: 10 },
          paddingTop: { xs: 5, md: 17 }
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          display={"flex"}
          sx={{
            justifyContent: { xs: "initial" }
          }}
          flexDirection="column"
          height="100%"
        >
          <Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "block"
                },
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Typography fontSize="30px" fontWeight="bold" gutterBottom>
                Forgot Password
              </Typography>
              <Typography
                color={styles.inputLabelColor}
                fontSize="16px"
                gutterBottom
              >
                Enter your registered Email ID
              </Typography>
            </Box>
            <Snackbar
              open={openSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={4000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={
                  snackbarMessage.includes("Success") ? "success" : "error"
                }
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Box component={"form"} mt={4} onSubmit={handleForgotPassword}>
              <StyledTextFieldContainer>
                <StyledInputLabel styledColors={styles} htmlFor="email">
                  Email Address
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  styledColors={styles}
                  variant="outlined"
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleEmailValidation}
                  required
                  error={formErrors.email}
                  helperText={formErrors.email && "Enter a valid email address"}
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || formErrors.email}
                sx={{
                  backgroundColor: "#368AD0",
                  cursor: "pointer",
                  color: "#fff",
                  width: "100%",
                  height: "42px",
                  textTransform: "none",
                  fontWeight: "bold",
                  ":disabled": {
                    backgroundColor: "#A7C8E9",
                    color: "#64AEDD",
                    pointerEvents: "none"
                  }
                }}
              >
                {loading ? <CircularProgress size={24} /> : "Send"}
              </Button>
            </Box>
          </Box>
          <Box
            mt={3}
            display="flex"
            gap="20px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Link
              to="/login"
              variant="outlined"
              style={{
                color: "#368AD0",
                textDecoration: "none"
              }}
            >
              Back to Sign In
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
