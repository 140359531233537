import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import { Input, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { updateCode } from "../../api/userApi";

// Styles for the code display box
const StyledCodeBox = styled(Box)(({ theme }) => ({
  border: "1px solid #d1d5db",
  borderRadius: "12px",
  padding: "12px",
  backgroundColor: "#f9fafb",
  fontFamily: "monospace",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    borderColor: "#6366f1",
    backgroundColor: "#eef2ff"
  },
  display: "flex",
  alignItems: "center",
  gap: "16px"
}));

// CodeDisplayBox Component
const CodeDisplayBox = React.memo(
  ({ type, label, codeStates, onCodeChange, onBlur, onStartEdit, onCopy }) => {
    const handleInputChange = (e) => onCodeChange(type, e.target.value);
    const handleBlur = () => onBlur(type);
    const handleEdit = () => onStartEdit(type);
    const handleCopy = () => onCopy(type);

    return (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        mr={type === "coaches" ? 2 : 0}
      >
        <Typography color="#111">{label} : </Typography>
        <StyledCodeBox>
          {codeStates[type].isEditing ? (
            <Input
              value={codeStates[type].code}
              onChange={handleInputChange}
              onBlur={handleBlur}
              autoFocus
              variant="outlined"
              rows={2}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#ffffff",
                  borderRadius: "6px"
                }
              }}
            />
          ) : (
            <Box
              component="pre"
              sx={{
                margin: 0,
                flexGrow: 1,
                color: "#374151",
                padding: "8px 0"
              }}
            >
              {codeStates[type].code}
            </Box>
          )}
          <Tooltip
            title={codeStates[type].isCopied ? "Copied!" : "Copy to Clipboard"}
          >
            <IconButton
              sx={{
                color: codeStates[type].isCopied ? "#10b981" : "#9ca3af",
                transition: "color 0.3s",
                "&:hover": {
                  color: codeStates[type].isCopied ? "#059669" : "#4b5563"
                }
              }}
              onClick={handleCopy}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={codeStates[type].isEditing ? "Save" : "Edit Code"}>
            <IconButton
              sx={{
                color: "#6b7280",
                transition: "color 0.3s",
                "&:hover": {
                  color: "#374151"
                }
              }}
              onClick={codeStates[type].isEditing ? handleBlur : handleEdit}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </StyledCodeBox>
      </Box>
    );
  }
);

// Main Dashboard Component
const CodeBlocks = () => {
  // States
  const [codeStates, setCodeStates] = React.useState({
    affiliate: {
      code: "",
      isEditing: false,
      isCopied: false
    },
    coaches: {
      code: "",
      isEditing: false,
      isCopied: false
    }
  });

  // Get current admin from Redux store
  const currentAdmin = useSelector((state) => state.adminauth.admin);

  // Initialize codes from admin data
  React.useEffect(() => {
    setCodeStates((prev) => ({
      affiliate: {
        ...prev.affiliate,
        code: currentAdmin?.codes?.affiliate || ""
      },
      coaches: {
        ...prev.coaches,
        code: currentAdmin?.codes?.coach || ""
      }
    }));
  }, [currentAdmin]);

  // Memoized handlers
  const handleCodeChange = React.useCallback((type, value) => {
    setCodeStates((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        code: value
      }
    }));
  }, []);

  const handleBlur = React.useCallback(
    async (type) => {
      if (!codeStates[type].isEditing) return;

      try {
        const response = await updateCode(codeStates[type].code, undefined, {
          affiliateCode: codeStates?.affiliate?.code,
          coachCode: codeStates?.coaches?.code
        });

        if (response.message) {
          setCodeStates((prev) => ({
            ...prev,
            [type]: {
              ...prev[type],
              isEditing: false
            }
          }));
        }
      } catch (error) {
        console.error(`Failed to update ${type} code:`, error);
      }
    },
    [codeStates, currentAdmin?.referenceId]
  );

  const handleStartEdit = React.useCallback((type) => {
    setCodeStates((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        isEditing: true
      }
    }));
  }, []);

  const handleCopy = React.useCallback(
    async (type) => {
      try {
        await navigator.clipboard.writeText(codeStates[type].code);
        setCodeStates((prev) => ({
          ...prev,
          [type]: {
            ...prev[type],
            isCopied: true
          }
        }));
        setTimeout(() => {
          setCodeStates((prev) => ({
            ...prev,
            [type]: {
              ...prev[type],
              isCopied: false
            }
          }));
        }, 2000);
      } catch (err) {
        console.error(`Failed to copy ${type} code: `, err);
      }
    },
    [codeStates]
  );

  // Render code boxes
  const renderCodeBoxes = () => {
    if (!currentAdmin?.adminUserId) return null;

    return (
      <>
        <CodeDisplayBox
          type="coaches"
          label="Coaches Code"
          codeStates={codeStates}
          onCodeChange={handleCodeChange}
          onBlur={handleBlur}
          onStartEdit={handleStartEdit}
          onCopy={handleCopy}
        />
        <CodeDisplayBox
          type="affiliate"
          label="Affiliate Code"
          codeStates={codeStates}
          onCodeChange={handleCodeChange}
          onBlur={handleBlur}
          onStartEdit={handleStartEdit}
          onCopy={handleCopy}
        />
      </>
    );
  };

  // In your Toolbar component
  return (
    <Toolbar
      sx={{
        background: "#F9FAFB",
        justifyContent: "space-between",
        height: "95px"
      }}
    >
      {/* Other toolbar content */}
      <Box display="flex" alignItems="center">
        {renderCodeBoxes()}
        {/* Rest of your toolbar content */}
      </Box>
    </Toolbar>
  );
};

export default CodeBlocks;
