import createApi from "../utils/axiosInstance";

export const getAllUserDiaries = async (levelId, currentDate, userId) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-diary-day/${levelId}?currentDate=${currentDate}&selectedUserId=${userId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user diaries failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
export const getAllUserIndividualDiaries = async (levelId, userId) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/get-user-diary-logs?userId=${userId}&diaryLevelId=${levelId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user diaries failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllDiaryUserLevels = async (date, userId) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-user-diary-level?date=${date}&selectedUserId=${userId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user diary level failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllDiaryUserStats = async ({
  timeFrame,
  userId,
  date,
  levelName,
  includeToday,
  startDate = "",
  endDate = ""
}) => {
  try {
    const startDateAvailable = startDate ? `&customStartDate=${startDate}` : "";
    const endDateAvailable = endDate ? `&customEndDate=${endDate}` : "";
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-stats?includeToday=${includeToday}&userId=${userId}&date=${date}&timeFrame=${
        timeFrame + startDateAvailable + endDateAvailable
      }&levelName=${levelName}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Get user stats failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const completeDiary = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/complete-diary-day`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Complete diary failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteIngredients = async (levelId, diaryId, ingredientId) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/user/diary/delete-ingredient/${levelId}/${diaryId}/${ingredientId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Delete failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addIngredients = async (data) => {
  try {
    const api = createApi(true);

    const response = await api.post(`/user/diary/add-ingredient`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Add Ingredients failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateIngredients = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/edit-ingredient`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Update total failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const copyYesterdayData = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/user/diary/copy-ingredient`, {
      ...data
    });
    return response.data;
  } catch (error) {
    console.error(
      "Copy yesterday failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getLeaderBoardData = async (levelId, month, year) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/user/diary/get-leaderboard?levelId=${levelId}&month=${month}&year=${year}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting leaderboard data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getAllCustomFoodOptions = async (type) => {
  try {
    const api = createApi(true);
    const response = await api.get(
      `/custom-food/get-custom-food-item?type=${type}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting search data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addCustomFoodItems = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/custom-food/add-edit-custom-food-item`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Adding custom food items failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const deleteCustomFoodItem = async (id) => {
  try {
    const api = createApi(true);
    const response = await api.delete(
      `/custom-food/delete-custom-food-item/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "deleting delete custom food item  failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Add this function to your diaryApi.js file
export const updateCustomFoodOrder = async (orderData) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/custom-food/reorder`, orderData);

    return response.data;
  } catch (error) {
    console.error("Changing order failed:", error);

    // If error has response (like from Axios or fetch)
    if (error.response) {
      console.error("Error response data:", error.response.data);
      throw error.response.data;
    }

    // If no response, but still an error (e.g., network error, timeout)
    if (error.message) {
      console.error("Error message:", error.message);
      throw error.message;
    }

    // Fallback for unexpected error structures
    throw "Unknown error occurred during order update";
  }
};
