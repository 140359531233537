export const dailyValues = {
  vitamin_a: 900, // µg
  vitamin_d: 20, // µg
  vitamin_c: 90, // mg
  calcium: 1300, // mg
  iron: 18, // mg
  sodium: 2300, // mg
  potassium: 4700, // mg
  cholesterol: 300, // mg
  carbohydrate: 275, // g
  fat: 78, // g
  saturated_fat: 20, // g
  fiber: 28 //g
};
