import React, { useEffect } from "react";
import { Tabs, Tab, Box, Typography, Badge } from "@mui/material";
import AllMods from "../Tables/ModTable"; // Updated import
import { useDispatch, useSelector } from "react-redux";
import { getAllMeals, setLimit, setPage } from "../../features/mod/modSlice";
import {
  selectError,
  selectLoading,
  selectMealData,
  selectTotal,
  selectCurrentPage,
  selectLimit
} from "../../features/mod/modSelectors";
import dayjs from "dayjs";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      textTransform={"none"}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

const ModTab = ({
  searchValue,
  isRefresh,
  setIsRefresh,
  isAddingMeal,
  setIsAddingMeal,
  setScheduleDate,
  setEditData,
  value,
  setValue,
  currentTab,
  setCurrentTab,
  setConfirmDeleteMealModal
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = React.useState(null);
  const mod = useSelector(selectMealData); // Now fetched from Redux
  const totalMods = useSelector(selectTotal);
  const page = useSelector(selectCurrentPage);
  const limit = useSelector(selectLimit);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const counts = useSelector((state) => state?.users?.pendingMealsCount) || 0;

  useEffect(() => {
    if (!searchValue || searchValue?.length > 3) {
      dispatch(
        getAllMeals({
          page,
          limit,
          search: searchValue,
          currentDate: dayjs().format("YYYY-MM-DD"),
          status: currentTab !== "Scheduled" ? currentTab?.toLowerCase() : ""
        })
      );
    }
  }, [dispatch, isRefresh, date, page, limit, currentTab, searchValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setPage(0));
    dispatch(setLimit(10));
    setCurrentTab(
      newValue === 0
        ? "Scheduled"
        : newValue === 1
        ? "Pending"
        : newValue === 2
        ? "Past"
        : "Rejected"
    );
  };

  return (
    <Box mt={4}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        sx={{
          "& .MuiTab-root": { minWidth: "130px" },
          "& .MuiTabs-indicator": { background: "#1976d2" }
        }}
      >
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Scheduled"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label={
            counts ? (
              <Badge
                badgeContent={counts} // example badge count
                color="primary"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#FFF",
                    backgroundColor: "#F6541C",
                    left: "42px"
                  }
                }}
              >
                Pending
              </Badge>
            ) : (
              <>Pending</>
            )
          }
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Past"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Rejected"
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AllMods
          totalMods={totalMods}
          mod={mod}
          loading={loading}
          setIsAddingMeal={setIsAddingMeal}
          setScheduleDate={setScheduleDate}
          error={error}
          date={date}
          setDate={setDate}
          setIsRefresh={setIsRefresh}
          setEditData={setEditData}
          setConfirmDeleteMealModal={setConfirmDeleteMealModal}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AllMods
          totalMods={totalMods}
          setIsAddingMeal={setIsAddingMeal}
          mod={mod}
          loading={loading}
          error={error}
          currentTab={currentTab}
          date={date}
          setIsRefresh={setIsRefresh}
          setDate={setDate}
          setEditData={setEditData}
          setConfirmDeleteMealModal={setConfirmDeleteMealModal}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AllMods
          totalMods={totalMods}
          mod={mod}
          setEditData={setEditData}
          setIsAddingMeal={setIsAddingMeal}
          setScheduleDate={setScheduleDate}
          loading={loading}
          setIsRefresh={setIsRefresh}
          error={error}
          currentTab={currentTab}
          date={date}
          setConfirmDeleteMealModal={setConfirmDeleteMealModal}
          setDate={setDate}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AllMods
          totalMods={totalMods}
          setConfirmDeleteMealModal={setConfirmDeleteMealModal}
          setIsAddingMeal={setIsAddingMeal}
          mod={mod}
          setEditData={setEditData}
          loading={loading}
          setScheduleDate={setScheduleDate}
          error={error}
          currentTab={currentTab}
          setDate={setDate}
          setIsRefresh={setIsRefresh}
        />{" "}
      </TabPanel>
    </Box>
  );
};

export default ModTab;
