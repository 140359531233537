import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { Suspense, useState } from "react";
import RichTextEditor from "../Extra/RichTextEditor";
import { deleteFile } from "../../utils/deleteFile";
import { dataURLtoFile } from "../../utils/dataURLtoFile";
import { uploadFile } from "../../utils/uploadFile";
import { updateFAQforLevel } from "../../api/trainingApi";
import { getAllLevelDetails } from "../../features/training/trainingSlice";
import { useDispatch } from "react-redux";

const FAQModal = ({ open, onClose, htmlValue, levelName, userId }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const images = Array.from(doc.querySelectorAll("img"));

    const prevUrls = new Set();
    if (htmlValue) {
      const prevDoc = parser.parseFromString(htmlValue, "text/html");
      const prevImages = Array.from(prevDoc.querySelectorAll("img"));
      for (let prevImg of prevImages) {
        const prevSrc = prevImg.getAttribute("src");
        if (prevSrc) {
          prevUrls.add(prevSrc);
        }

        if (!value?.includes(prevSrc) && !prevSrc.startsWith("http")) {
          try {
            await deleteFile(
              prevSrc,
              process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
            );
          } catch (error) {
            console.error("Error deleting previous image:", error);
          }
        }
      }
    }
    for (let img of images) {
      let src = img.getAttribute("src");
      const originalSrc = src.split("?")[0];
      const fileName = originalSrc ? originalSrc?.split("/")?.at(-1) : "";

      if (src.includes(fileName)) {
        if (prevUrls.has(fileName)) {
          img.setAttribute("src", fileName);
          continue;
        }
      }

      // Handle new base64 images
      if (src.startsWith("data:image")) {
        const file = dataURLtoFile(src, `image-${Date.now()}.png`);
        const uniqueFileName = `${Date.now()}-${file.name}`;
        try {
          await uploadFile(
            file,
            uniqueFileName,
            process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
          );

          img.setAttribute("src", uniqueFileName);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    }

    await updateFAQforLevel(doc.body.innerHTML, levelName, userId);
    setValue("");
    dispatch(getAllLevelDetails(userId));
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="meal-modal-title"
      aria-describedby="meal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "20%",
          overflow: "auto",
          height: { xs: "80%", md: "80%" },
          width: { md: "60%", xs: "100%" },
          borderRadius: 2
          //   transform: "translate(-50%, -50%)"
        }}
      >
        <Box
          sx={{
            padding: { xs: "28px", md: "10px" },
            position: "relative",
            bgcolor: "#FCFCFC",
            borderRadius: "16px 16px 0 0",
            boxShadow: 24,
            overflow: "hidden" // Ensures pseudo-element stays within the box
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="flex-end"
            width="100%"
          >
            <IconButton
              sx={{
                svg: {
                  fill: "#1c1c1c"
                }
              }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            width={"100%"}
            sx={{ padding: { sm: 0, md: "10px 25px" } }}
            display={"flex"}
            gap="10px"
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            FAQ Page
            <Box width={"100%"}>
              <Suspense fallback={<div>Loading...</div>}>
                <RichTextEditor
                  defaultValue={htmlValue}
                  value={value}
                  setValue={setValue}
                />
              </Suspense>
            </Box>
          </Box>
          <Button
            onClick={handleSubmit}
            disabled={!value}
            sx={{
              textTransform: "none",
              color: "#fcfcfc",
              padding: "12px 20.5px",
              background: "#1976d2",
              fontWeight: 700,
              "&:disabled": {
                backgroundColor: "#A7C8E9",
                color: "#FCFCFC",
                pointerEvents: "none"
              }
            }}
          >
            {" "}
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FAQModal;
