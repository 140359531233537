import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Avatar,
  Chip,
  linearProgressClasses,
  Accordion,
  InputLabel,
  Switch,
  useMediaQuery
} from "@mui/material";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as BananaIcon } from "../../assets/banana.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { ReactComponent as CoachIcon } from "../../assets/coach-badge.svg";
import { ReactComponent as ConstantIcon } from "../../assets/constant.svg";
import { ReactComponent as BicepIcon } from "../../assets/bicep.svg";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DateRangePicker from "../Extra/DateRangePicker";
import DiaryTracker from "../Extra/DiaryTracker";
import { ReactComponent as TrophyIcon } from "../../assets/goal-trophy.svg";
import {
  selectAllDiaryExtraData,
  selectAllDiaryOutOf800g,
  selectAllDiaryOutOfCalories,
  selectAllDiaryOutOfProtein,
  selectAllDiaryStats,
  selectAllDiaryStatsCounts
} from "../../features/diary/diarySelectors";
import { getCurrentUserDiaryStats } from "../../features/diary/diarySlice";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import getInitials from "../../utils/getInitials";
import useSignedUrl from "../../utils/useSignedUrl";
import useThemeColor from "../../utils/useTheme";
import { options800gms, optionsProteins } from "../../utils/mockWeightOptions";
import { convertItemQuantityToGrams } from "../../utils/weightConvert";
import formatNumber from "../../utils/formatNumber";
import { selectLevelDetails } from "../../features/training/trainingSelectors";
import { getAllLevelDetails } from "../../features/training/trainingSlice";
import MuiNutritionCharts from "../Extra/Graph";
import { dailyValues } from "../../utils/calculateDVPercent";
import { useTheme } from "@emotion/react";

const ImageItem = ({ img }) => {
  const imgSrc = useSignedUrl(
    img,
    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
  );

  return (
    <Box
      component="img"
      borderRadius="50%"
      height={100}
      width={100}
      src={imgSrc}
      alt="avatar"
    />
  );
};

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, activeColor, background }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: background
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: activeColor
    }
  })
);
const includedMicroNutrientFields = [
  "calcium",
  "iron",
  "potassium",
  "sodium",
  "vitamin_a",
  "vitamin_c",
  "vitamin_d"
];

const nutrientFields = (data) => {
  if (data)
    return Object.entries(data).filter(([key, value]) => {
      return includedMicroNutrientFields.includes(key) && !isNaN(Number(value));
    });
};

const formattedNutrients = (data) => {
  return nutrientFields?.(data)?.map(([key, value]) => {
    const numericValue = parseFloat(value).toFixed(2) || 0;
    const isSmallValue = numericValue && numericValue < 1;
    return {
      name: key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      value: isSmallValue ? numericValue * 1000 : numericValue,
      unit: isSmallValue ? "mg" : "g"
    };
  });
};
const StatsListModal = ({ open, setOpen }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState("");
  const [includeToday, setIncludeToday] = useState(true);
  const dispatch = useDispatch();
  const [levelName, setActiveDiaryLevel] = useState(open?.diaryLevelName);
  const extraData = useSelector(selectAllDiaryExtraData);
  const allStats = useSelector(selectAllDiaryStats);
  const allStatsCount = useSelector(selectAllDiaryStatsCounts);
  const total800g = useSelector(selectAllDiaryOutOf800g);
  const totalProteins = useSelector(selectAllDiaryOutOfProtein);
  const totalCalories = useSelector(selectAllDiaryOutOfCalories);
  const availableLevels = useSelector(selectLevelDetails) || [];
  const selectedFilter = localStorage.getItem("selectedStatFilter");

  useEffect(() => {
    if (selectedFilter) setValue(selectedFilter);
    else setValue("7days");
  }, [selectedFilter]);
  // const levelName = open?.diaryLevelName;
  const handleClose = () => {
    setOpen({});
    setValue(selectedFilter ? selectedFilter : "7days");
  };
  useEffect(() => {
    dispatch(getAllLevelDetails());
  }, [dispatch]);

  useEffect(() => {
    if (open?.diaryLevelName) setActiveDiaryLevel(open?.diaryLevelName);
  }, [open]);
  useEffect(() => {
    if (value)
      if (
        open.userId &&
        ((value === "custom" && startDate && endDate) || value !== "custom")
      )
        dispatch(
          getCurrentUserDiaryStats({
            levelName: levelName,
            userId: open.userId,
            timeFrame: value,
            includeToday,
            date: dayjs().format("YYYY-MM-DD"),
            ...(value === "custom" && {
              startDate: dayjs(startDate).format("YYYY-MM-DD"),
              endDate: dayjs(endDate).format("YYYY-MM-DD")
            })
          })
        );
  }, [
    dispatch,
    levelName,
    value,
    includeToday,
    open.userId,
    startDate,
    endDate
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const calculateAverage800gms = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalEntry800g || 0),
      0
    );
    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.metric_serving_amount
            : sum / allStatsCount?.entry800gCount
        ) || 0,
      // average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOf800g || total800g
    };
  }, [allStats, total800g]);

  const calculateAverageProtein = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );

    return {
      average:
        Math.round(
          levelName === "level3"
            ? extraData?.averagedSummaries?.protein
            : sum / allStatsCount?.proteinCount
        ) || 0,
      // average: Math.round(sum / allStats?.length) || 0,
      total: allStats[0]?.outOfProtein || totalProteins
    };
  }, [allStats, totalProteins]);

  const calculateAverageCalories = useMemo(() => {
    const sum = allStats?.reduce(
      (prev, curr) => prev + (curr.totalProtein || 0),
      0
    );
    return {
      average: Math.round(extraData?.averagedSummaries?.calories || 0) || 0,
      total: allStats[0]?.outOfCalories || totalCalories
    };
  }, [allStats, totalCalories]);

  const styles = useThemeColor();

  const MetricBox = ({ label, value, percentage }) => (
    <Box sx={{ minWidth: "90px" }}>
      <Typography
        fontSize="12px"
        color={styles.lightBlack}
        sx={{ textAlign: "left" }}
      >
        {value}
        {label && !label?.startsWith("g") ? ` ${label}` : label}
      </Typography>
      <Typography fontSize="12px" sx={{ textAlign: "left" }}>
        {percentage ? `${percentage}%` : ""}
      </Typography>
    </Box>
  );
  const correctedCalories = Math.round(
    (Number(extraData?.averagedSummaries?.protein) || 0) * 4 +
      (Number(extraData?.averagedSummaries?.carbohydrate) || 0) * 4 +
      (Number(extraData?.averagedSummaries?.fat) || 0) * 9
  );
  const PillarSection = ({ title, color, data, averages }) => (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography
        variant="body2"
        fontWeight="bold"
        color={styles.color}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          mb: 1
        }}
      >
        <Box
          component="span"
          sx={{
            height: "8px",
            width: "8px",
            backgroundColor: color,
            borderRadius: "50%",
            display: "inline-block"
          }}
        />
        {title}
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)", // 2 columns on mobile
            sm: "repeat(3, 1fr)", // 3 columns on tablet
            md: "20% 20% 20% 20% 20%" // 5 equal columns on desktop
          },
          gap: 2,
          mb: 2,
          alignItems: "start"
        }}
      >
        <MetricBox
          label="Cals"
          value={formatNumber(Number(data?.calories)) || 0}
          percentage={
            data?.calories
              ? Math.round(
                  ((Number(data?.calories) || 0) /
                    (extraData?.averagedSummaries?.calories || 1)) *
                    100
                )
              : 0
          }
        />
        <MetricBox
          label="g Protein"
          value={formatNumber(Number(data?.protein)) || 0}
          percentage={
            +data?.protein
              ? Math.round(
                  ((Number(data?.protein) || 0) /
                    extraData?.averagedSummaries?.protein || 1) * 100
                )
              : 0
          }
        />
        <MetricBox
          label="g Carbs"
          value={formatNumber(Number(data?.carbohydrate)) || 0}
          percentage={
            +data?.carbohydrate
              ? Math.round(
                  ((Number(data?.carbohydrate) || 0) /
                    (averages?.carbohydrate || 1)) *
                    100
                )
              : 0
          }
        />
        <MetricBox
          label="g Fiber"
          value={formatNumber(Number(data?.fiber)) || 0}
          percentage={
            +data?.fiber
              ? Math.round(
                  ((Number(data?.fiber) || 0) / (averages?.fiber || 1)) * 100
                )
              : 0
          }
        />
        <MetricBox
          label="g Fat"
          value={formatNumber(Number(data?.fat)) || 0}
          percentage={
            +data?.fat
              ? Math.round(
                  ((Number(data?.fat) || 0) / (averages?.fat || 1)) * 100
                )
              : 0
          }
        />
      </Box>
    </Box>
  );
  const formattedNutrients = nutrientFields(extraData?.averagedSummaries)?.map(
    ([key, value]) => {
      const numericValue = Math.round(parseFloat(value) || 0);
      return {
        name: key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase()),
        value: numericValue,
        unit: "g",
        percent: Math.round(((parseFloat(value) || 0) / dailyValues[key]) * 100)
      };
    }
  );

  const getNutrientValue = (name, unit = "") => {
    const nutrient = formattedNutrients?.find((n) => n.name === name);
    if (!nutrient) return `0${unit || ""}(0%)`; // Fallback if nutrient is not found
    const value = nutrient?.value || 0; // Default to 0 if value is undefined or null
    const percent = nutrient?.percent || 0; // Default to 0 if percent is undefined or NaN
    const unitString = unit || nutrient?.unit || ""; // Default to empty string if no unit is provided

    return `${formatNumber(value)}${unitString}${` (${formatNumber(
      percent
    )}%)`}`;
  };

  const NutrientRow = ({ name, unit }) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={0.5}
    >
      <Typography fontSize="13px">{name}</Typography>
      <Typography fontSize="13px">{getNutrientValue(name, unit)}</Typography>
      {/* <Typography fontSize="13px">{calculatePercentDV(name, unit)}</Typography> */}
    </Box>
  );

  return (
    <>
      {/* Modal */}
      <Dialog
        sx={{
          width: "100%",
          ".MuiPaper-root": {
            maxWidth: "100%",
            width: "90vw", // Set a fixed width relative to viewport
            transition: "none !important"
          }
        }}
        open={Object.keys(open).length}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Stats
          </Typography>
          {/* <Button onClick={handleClose} style={{ float: "right" }}>
            Close
          </Button> */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box display="flex" gap="20px" padding={2}>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              px={2}
              py={3}
              borderRadius={2}
              alignItems="center"
              sx={{ background: "#F3F4F6", maxWidth: "30%" }}
            >
              {open?.profilePic ? (
                <ImageItem img={open?.profilePic} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, mr: 1, fontSize: 29 }}>
                  {open.fullName
                    ? getInitials(open.fullName)
                    : getInitials(open.displayName)}
                </Avatar>
              )}
              <Typography
                variant="h6"
                fontWeight={600}
                fontSize={16}
                textAlign="center"
              >
                {open?.fullName}
              </Typography>
              <Chip
                label={"User"}
                sx={{
                  backgroundColor: "#FCECD3",
                  fontSize: "12px",
                  color: "#F1B44C"
                }}
              />
              {open?.approvedMealsCount ||
              open?.completedLevel?.length ||
              !open?.max800gStreak >= 30 ||
              (open?.isAdmin && open?.userType === "Coach") ||
              open?.isConsistent ||
              open?.maxStreak ? (
                <Box
                  display="flex"
                  mt={2}
                  ml={2}
                  gap={2}
                  alignItems="center"
                  overflow="auto"
                  flexWrap="wrap" // Allow wrapping to the next line
                  justifyContent="center" // Align items to the left
                  sx={{
                    "::-webkit-scrollbar": {
                      display: "none"
                    }
                  }}
                >
                  {open?.approvedMealsCount ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <ChefIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Chef
                      </Typography>
                      <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.approvedMealsCount}
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.at(-1) === "level1" ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl1Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 1
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.at(-1) === "level2" ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl2Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 2
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.completedLevel?.at(-1) === "level3" ? (
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <Lvl3Icon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Training Lvl 3
                      </Typography>
                    </Box>
                  ) : null}
                  {open?.isAdmin && open?.userType === "Coach" ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Coach
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Three Pillars Method® Coach. Fulfilled the
                    //         requirements of the Coaches
                    //         Masterclass and affiliation.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <CoachIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Coach
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                  {open?.maxStreak ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Streaking
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Represents your greatest streak of diary logging
                    //         days in a row.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    // <Box>
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <ConstantIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Streaking
                      </Typography>
                      <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          padding:
                            String(open?.maxStreak)?.length === 1
                              ? "1px 7px"
                              : "1px 4px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.maxStreak}
                      </Typography>
                    </Box>
                  ) : (
                    //   <Typography>Streaking</Typography>
                    // </Box>
                    // </Tooltip>
                    <></>
                  )}
                  {open?.isConsistent ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Consistency
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Appears when you've logged for at least 11 of the
                    //         last 14 days.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    //   {" "}
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <BicepIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Consistency
                      </Typography>
                      {/* <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          padding:
                            String(open?.isConsistent)?.length === 1
                              ? "1px 7px"
                              : "1px 4px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.isConsistent}
                      </Typography> */}
                    </Box>
                  ) : (
                    // </Tooltip>
                    <></>
                  )}
                  {!open?.max800gStreak >= 30 ? (
                    // <Tooltip
                    //   slotProps={{
                    //     tooltip: {
                    //       sx: {
                    //         padding: 0
                    //       }
                    //     }
                    //   }}
                    //   title={
                    //     <Box
                    //       sx={{
                    //         margin: 0,
                    //         padding: 2,
                    //         background: styles.color,
                    //         color: styles.background,
                    //         display: "flex",
                    //         justifyContent: "center",
                    //         flexDirection: "column",
                    //         alignItems: "center"
                    //       }}
                    //     >
                    //       <Typography fontWeight="bold" fontSize={16}>
                    //         Consistency
                    //       </Typography>
                    //       <Typography fontSize={13}>
                    //         Appears when you've logged for at least 11 of the
                    //         last 14 days.
                    //       </Typography>
                    //     </Box>
                    //   }
                    // >
                    //   {" "}
                    <Box
                      display="flex"
                      height={100}
                      width={100}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                      position="relative"
                    >
                      <BananaIcon height={80} width={80} />
                      <Typography fontSize="0.7rem" variant="bold">
                        Secret
                      </Typography>
                      {/* <Typography
                        color="#F6541C"
                        padding="0px 7px"
                        borderRadius="50%"
                        fontSize="smaller"
                        sx={{
                          background: "#FCFCFC",
                          position: "absolute",
                          bottom: "50px",
                          padding:
                            String(open?.isConsistent)?.length === 1
                              ? "1px 7px"
                              : "1px 4px",
                          right: "20px",
                          transform: "translate(50%, 50%)"
                        }}
                      >
                        {open?.isConsistent}
                      </Typography> */}
                    </Box>
                  ) : (
                    // </Tooltip>
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={3}
              width="100%"
              sx={{
                flexGrow: 1,
                maxWidth: "100%", // Constrain width of right section
                overflow: "hidden" // Prevent content from expanding container
              }}
            >
              {/* Dropdown & Custom Date Picker */}
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={"column"}
                gap={2}
                mt={levelName ? 1 : 0}
              >
                <FormControl sx={{ width: "60%" }}>
                  <InputLabel>Level</InputLabel>
                  <Select
                    value={levelName}
                    label="Level"
                    onChange={(e) => setActiveDiaryLevel(e.target.value)}
                  >
                    {availableLevels?.map((item) => (
                      <MenuItem value={item.levelName}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box display="flex" justifyContent={"space-between"}>
                  <Typography variant="h6" fontWeight="bold">
                    Diet Statistics
                  </Typography>
                  {value === "today" || value === "custom" ? (
                    <Box
                      display="flex"
                      alignItems={"center"}
                      // flexDirection={isMobile ? "column" : "row"}
                    ></Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems={"center"}
                      // flexDirection={isMobile ? "column" : "row"}
                    >
                      <Typography color={styles.color} fontSize={"13px"}>
                        Include Today
                      </Typography>
                      <Switch
                        checked={includeToday}
                        onChange={(e) => {
                          setIncludeToday(e.target.checked);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <FormControl
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    marginTop: "15px",
                    svg: { fill: "#1C1C1C" },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#F5F5F5" },
                      "&:hover fieldset": { borderColor: "#1C1C1C" },
                      "&.Mui-focused fieldset": { borderColor: "#1C1C1C" }
                    }
                  }}
                >
                  <Select
                    labelId="range-label"
                    name="range"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                      if (e.target.value !== "custom")
                        localStorage.setItem(
                          "selectedStatFilter",
                          e.target.value
                        );
                    }}
                    sx={{ color: "#1C1C1C" }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="7days">7 Days</MenuItem>
                    <MenuItem value="10days">10 Days</MenuItem>
                    <MenuItem value="30days">30 Days</MenuItem>
                    <MenuItem value="custom">Custom Time</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {value === "custom" && (
                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              )}

              <Box
                display="flex"
                justifyContent="center"
                sx={{
                  borderRadius: "16px"
                }}
              >
                <DiaryTracker
                  title={"#800gChallenge"}
                  nonEditable
                  levelName={levelName}
                  strokeColor={"#F6541C"}
                  value={calculateAverage800gms?.average}
                  maxValue={calculateAverage800gms?.total}
                  height={levelName === "level2" ? 210 : 250}
                  width={levelName === "level2" ? 300 : 350}
                />
                {levelName !== "level1" && (
                  <DiaryTracker
                    title={"Protein"}
                    nonEditable
                    levelName={levelName}
                    strokeColor={"#368AD0"}
                    value={calculateAverageProtein?.average}
                    maxValue={calculateAverageProtein?.total}
                    height={levelName === "level2" ? 210 : 250}
                    width={levelName === "level2" ? 300 : 350}
                  />
                )}
                {levelName === "level3" && (
                  <DiaryTracker
                    nonEditable
                    isCalories
                    strokeColor={"#FF9431"}
                    levelName={levelName}
                    value={calculateAverageCalories?.average}
                    maxValue={calculateAverageCalories?.total}
                    title={"Calories"}
                    heightX={levelName === "level2" ? 210 : 250}
                    widthX={levelName === "level2" ? 300 : 350}
                  />
                )}
              </Box>
              {levelName === "level3" ? (
                <>
                  {" "}
                  <Box>
                    <Typography fontSize="15px" color={styles.inputLabelColor}>
                      Protein Pillar
                    </Typography>
                    <Box
                      mt={1}
                      width="100%"
                      borderRadius="8px"
                      padding={"18px"}
                      sx={{ background: styles.lightGreyColor }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection="column"
                        alignItems={"start"}
                        gap="10px"
                      >
                        <Box
                          width="40%"
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Typography fontWeight="bold" fontSize="12px">
                              {Math.round(
                                ((Number(
                                  +extraData?.totalSummaries?.proteinSummary
                                    ?.protein || 0
                                ) *
                                  4) /
                                  Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.calories
                                  )) *
                                  100 || 0
                              )}
                              % Protein
                            </Typography>
                          </Box>
                          <Box>
                            <Typography fontWeight="bold" fontSize="12px">
                              {Math.round(
                                Number(
                                  +extraData?.totalSummaries?.proteinSummary
                                    ?.calories || 0
                                ) -
                                  (Number(
                                    extraData?.totalSummaries?.proteinSummary
                                      ?.protein
                                  ) * 4 || 0)
                              )}{" "}
                              Carb + Fat Cals
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontSize="15px" color={styles.color}>
                      Three Pillars Method
                    </Typography>
                    <Box
                      mt={1}
                      width="100%"
                      borderRadius="8px"
                      padding="18px"
                      sx={{ background: styles.lightGreyColor }}
                    >
                      <PillarSection
                        title="800g"
                        color="#F6541C"
                        data={extraData?.totalSummaries?.entry800gSummary}
                        averages={extraData?.averagedSummaries}
                      />
                      <Divider
                        sx={{
                          background: "#8E8E8E",
                          width: "100%",
                          m: "10px 0"
                        }}
                      />
                      <PillarSection
                        title="Protein"
                        color="#368AD0"
                        data={extraData?.totalSummaries?.proteinSummary}
                        averages={extraData?.averagedSummaries}
                      />
                      <Divider
                        sx={{
                          background: "#8E8E8E",
                          width: "100%",
                          m: "10px 0"
                        }}
                      />
                      <PillarSection
                        title="Other"
                        color="#FF9431"
                        data={extraData?.totalSummaries?.otherFoodSummary}
                        averages={extraData?.averagedSummaries}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography fontSize="15px" color={styles.inputLabelColor}>
                      Macronutrients
                    </Typography>
                    <Box
                      mt={1}
                      width="100%"
                      borderRadius="8px"
                      padding="18px"
                      sx={{ background: styles.lightGreyColor }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="column"
                        alignItems="start"
                        gap="10px"
                      >
                        <Box
                          mt={1}
                          width="100%"
                          borderRadius="8px"
                          sx={{ background: styles.lightGreyColor }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: {
                                xs: "repeat(2, 1fr)", // 2 columns on mobile
                                sm: "repeat(3, 1fr)", // 3 columns on tablet
                                md: "20% 20% 20% 20% 20%" // 5 equal columns on desktop
                              },
                              gap: 2,
                              mb: 2,
                              alignItems: "start"
                            }}
                          >
                            <MetricBox
                              label="Cals"
                              value={calculateAverageCalories?.average}
                            />
                            <MetricBox
                              label="Corrected Cals"
                              value={correctedCalories}
                            />
                            <MetricBox
                              label="g Protein"
                              value={
                                formatNumber(
                                  Number(extraData?.averagedSummaries?.protein)
                                ) || 0
                              }
                              percentage={Math.round(
                                (((Number(
                                  extraData?.averagedSummaries?.protein
                                ) || 0) *
                                  4) /
                                  correctedCalories || 0) * 100
                              )}
                            />
                            <MetricBox
                              label="g Carbs"
                              value={
                                formatNumber(
                                  Number(
                                    extraData?.averagedSummaries?.carbohydrate
                                  )
                                ) || 0
                              }
                              percentage={Math.round(
                                (((Number(
                                  extraData?.averagedSummaries?.carbohydrate
                                ) || 0) *
                                  4) /
                                  correctedCalories || 0) * 100
                              )}
                            />
                            <MetricBox
                              label="g Fat"
                              value={
                                formatNumber(
                                  Number(extraData?.averagedSummaries?.fat)
                                ) || 0
                              }
                              percentage={Math.round(
                                (((Number(extraData?.averagedSummaries?.fat) ||
                                  0) *
                                  9) /
                                  correctedCalories || 0) * 100
                              )}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: {
                                xs: "repeat(2, 1fr)", // 2 columns on mobile
                                sm: "repeat(3, 1fr)", // 3 columns on tablet
                                md: "20% 20% 20% 20% 20%" // 5 equal columns on desktop
                              },
                              gap: 2,
                              mb: 2,
                              alignItems: "start"
                            }}
                          >
                            {isMobile ? (
                              <></>
                            ) : (
                              <>
                                <MetricBox />
                                <MetricBox />
                                <MetricBox />
                              </>
                            )}
                            <MetricBox
                              label="g Fiber"
                              value={
                                formatNumber(
                                  Number(
                                    extraData?.averagedSummaries?.fiber
                                  )?.toFixed(2)
                                ) || 0
                              }
                            />
                            <MetricBox
                              label="g Saturated Fat"
                              value={
                                formatNumber(
                                  Number(
                                    extraData?.averagedSummaries?.saturated_fat
                                  )?.toFixed(2)
                                ) || 0
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {extraData?.averagedSummaries &&
                  Object.keys(extraData?.averagedSummaries)?.length ? (
                    <Box>
                      <Typography
                        fontSize="15px"
                        color={styles.inputLabelColor}
                      >
                        Select Micronutrients
                      </Typography>
                      <Box
                        width="100%"
                        padding={"18px"}
                        sx={{
                          background: styles.lightGreyColor,
                          borderRadius: 2,
                          mt: 1
                        }}
                        borderRadius={1}
                      >
                        {/* Vitamins and Minerals Group */}
                        <NutrientRow name="Calcium" unit="mg" />
                        <NutrientRow name="Iron" unit="mg" />
                        <NutrientRow name="Potassium" unit="mg" />
                        <NutrientRow name="Sodium" unit="mg" />
                        <NutrientRow name="Vitamin A" unit="mcg" />
                        <NutrientRow name="Vitamin C" unit="mg" />
                        <NutrientRow name="Vitamin D" unit="mcg" />
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <Box
                display="flex"
                flexDirection="column"
                width={"100%"}
                gap={2}
                mb={2}
                sx={
                  {
                    // overflowY: "auto",
                    // maxHeight: "295px",
                    // "::-webkit-scrollbar": {
                    //   display: "none"
                    // }
                  }
                }
              >
                <MuiNutritionCharts
                  total800g={total800g}
                  totalProteins={totalProteins}
                  totalCalories={totalCalories}
                  data={allStats}
                  levelName={levelName}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StatsListModal;
