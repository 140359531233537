import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLimit, setPage } from "../../features/training/trainingSlice";
import AudioPlayer from "../Extra/AudioPlayer";
import {
  selectTrainingData,
  selectTotal
} from "../../features/training/trainingSelectors";
import useSignedUrl from "../../utils/useSignedUrl";

// Custom CSS styles
const styles = {
  tableContainer: {
    borderRadius: "10px 10px 0 0",
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#374151",
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1"
    }
  },
  headerCell: {
    minWidth: 100,
    backgroundColor: "#374151",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: 700
    // textAlign: "center"
  },
  tableRow: {
    fontSize: "12px"
  }
};

const columns = [
  { id: "day", label: "Day", align: "left", width: 120 },
  { id: "audio", label: "Audio", align: "left", width: 150 },
  { id: "title", label: "Title", align: "left", minWidth: 140 },
  { id: "actions", label: "", align: "center", minWidth: 130 }
];

const AudioItem = ({ audio }) => {
  const src = useSignedUrl(
    audio,
    process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
  );
  return <AudioPlayer src={src} />;
};

const StickyHeadTable = ({
  isAddingTraining,
  setEditData,
  setIsAddingTraining,
  setConfirmDeleteModal
}) => {
  const [page, setNewPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const allTrainingsData = useSelector(selectTrainingData) || [];
  const totalCount = useSelector(selectTotal) || 0;

  const handleChangePage = (event, newPage) => {
    setNewPage(newPage);
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setNewPage(0);
    dispatch(setPage(0));
    dispatch(setLimit(+event.target.value));
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    ...styles.headerCell,
                    width: column.width,
                    textAlign: column.align
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allTrainingsData?.map((row, index) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.trainingId}
              >
                <TableCell style={styles.tableRow} align="left">
                  <Typography fontSize="small">{row.day}</Typography>
                </TableCell>
                <TableCell style={styles.tableRow} align="left">
                  <AudioItem audio={row.audioFile} />
                </TableCell>
                <TableCell style={styles.tableRow} align="left">
                  <Typography fontSize="small">{row.title}</Typography>
                </TableCell>
                <TableCell style={styles.tableRow} align="right">
                  <Box display="flex" justifyContent="center" gap="10px">
                    <IconButton
                      onClick={() => {
                        setIsAddingTraining(true);
                        setEditData(row);
                      }}
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      size="small"
                      aria-label="edit"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setConfirmDeleteModal(row?.trainingId);
                      }}
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      size="small"
                      aria-label="delete"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StickyHeadTable;
