import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as MusicIcon } from "../../assets/music.svg";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { getUniqueSuffix } from "../../utils/getUniqueSuffix";
import { uploadFile } from "../../utils/uploadFile";
import { deleteFile } from "../../utils/deleteFile";
import useSignedUrl from "../../utils/useSignedUrl";
import AudioPlayer from "../Extra/AudioPlayer";
import { dataURLtoFile } from "../../utils/dataURLtoFile";
import {
  addNewTrainings,
  updateTrainings
} from "../../features/training/trainingSlice";
import { selectLevelDetails } from "../../features/training/trainingSelectors";
const RichTextEditor = lazy(() => import("../Extra/RichTextEditor"));

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(1, 0, 3, 3)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  color: "#333333",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: "14px",
    background: "#FCFCFC",
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#D1D5DB"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D1D5DB"
    }
  }
}));

const days = Array.from({ length: 50 }, (_, index) => `Day ${index + 1}`);

const AddTraining = ({ setIsAddingTraining, editData }) => {
  const audioSrcString = useSignedUrl(
    editData?.audioFile,
    process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
  );
  const [formData, setFormData] = useState({
    level: editData?.levelName || "level1",
    day: editData?.day ?? "",
    title: editData?.title ? editData.title : "",
    subtitle: editData?.subTitle ? editData.subTitle : "",
    audio: "",
    generalText: ""
  });
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const availableLevels = useSelector(selectLevelDetails) || [];

  const scheduledDays = useMemo(() => {
    return availableLevels?.find((item) => item.levelName === formData?.level)
      ?.scheduledDays;
  }, [formData, availableLevels]);
  // Handle input change for title, generalText, and other inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (editData && editData.audioFile) {
      // Ensure formData is updated properly without referencing stale state
      setFormData((prevFormData) => ({
        ...prevFormData,
        audio: audioSrcString
      }));
    }
  }, [editData, audioSrcString]);

  const [audioSrc, setAudioSrc] = useState(null); // Store audio URL
  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, audio: file }));
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 10MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setAudioSrc(event.target.result); // Set audio source to the data URL
        setFormData((prev) => ({ ...prev, audio: file }));
      };

      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!formData.title || (!editData && !formData.audio)) {
        setSnackbarMessage(
          `${
            !formData.title
              ? "Title"
              : !editData && !formData.audio
              ? "Training audio"
              : ""
          } is required`
        );
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      // Prepare data object
      const data = {
        day: formData.day,
        title: formData.title,
        generalText: "",
        subTitle: formData.subtitle,
        level: formData.level
      };

      const uniqueFileName = getUniqueSuffix(formData.audio?.name);

      const jsonData = {
        ...data,
        ...(formData.audio?.name ? { audioFile: uniqueFileName } : {})
      };
      if (formData.audio?.name) {
        await uploadFile(
          formData.audio,
          uniqueFileName,
          process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
        );

        if (editData?.audioFile) {
          await deleteFile(
            editData?.audioFile,
            process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
          );
        }
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(value, "text/html");
      const images = Array.from(doc.querySelectorAll("img"));

      const prevUrls = new Set();
      if (editData?.generalText) {
        const prevDoc = parser.parseFromString(
          editData.generalText,
          "text/html"
        );
        const prevImages = Array.from(prevDoc.querySelectorAll("img"));
        for (let prevImg of prevImages) {
          const prevSrc = prevImg.getAttribute("src");
          if (prevSrc) {
            prevUrls.add(prevSrc);
          }

          if (!value?.includes(prevSrc) && !prevSrc.startsWith("http")) {
            try {
              await deleteFile(
                prevSrc,
                process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
              );
            } catch (error) {
              console.error("Error deleting previous image:", error);
            }
          }
        }
      }
      for (let img of images) {
        let src = img.getAttribute("src");
        const originalSrc = src.split("?")[0];
        const fileName = originalSrc ? originalSrc?.split("/")?.at(-1) : "";

        if (src.includes(fileName)) {
          if (prevUrls.has(fileName)) {
            img.setAttribute("src", fileName);
            continue; 
          }
        }

        // Handle new base64 images
        if (src.startsWith("data:image")) {
          const file = dataURLtoFile(src, `image-${Date.now()}.png`);
          const uniqueFileName = `${Date.now()}-${file.name}`;

          try {
            await uploadFile(
              file,
              uniqueFileName,
              process.env.REACT_APP_BACKEND_TRAINING_IMAGE_LOCATION
            );

            img.setAttribute("src", uniqueFileName); 
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        }
      }

      // Serialize the document back to an HTML string
      jsonData.generalText = doc.body.innerHTML;
      const { payload } = await dispatch(
        editData
          ? updateTrainings({
              trainingData: jsonData,
              levelId: editData?.levelId,
              trainingId: editData?.trainingId
            })
          : addNewTrainings(jsonData)
      );

      setLoading(false);
      if (!payload.error) {
        setIsAddingTraining(false);
      } else {
        setSnackbarMessage(
          payload.error || "Failed to add training. Please try again."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to add training. Please try again.");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box component="form" margin="24px 40px 200px 0" display="flex">
      <Box width={"70%"}>
        <StyledTextFieldContainer>
          <Typography
            ml={0.5}
            color={"#333333"}
            fontWeight="bold"
            fontSize="14px"
            htmlFor="title"
          >
            Select Level
          </Typography>
          <Box mt={1} display="flex" gap={4}>
            {availableLevels.map(({ levelName, title }) => (
              <Button
                key={levelName}
                disabled={editData?.levelId}
                fullWidth
                onClick={() => setFormData({ ...formData, level: levelName })}
                sx={{
                  ...(editData?.levelName === levelName && {
                    ":disabled": {
                      backgroundColor: "#A7C8E9",
                      color: "#FFF"
                    }
                  }),
                  height: "100%",
                  padding: "15px 20px",
                  border: `1px solid ${
                    formData.level === levelName ? "#1976d2" : "#D1D5DB"
                  }`,
                  borderRadius: 1,
                  backgroundColor:
                    formData.level === levelName ? "#A7C8E9" : "transparent"
                }}
              >
                <Box
                  width="100%"
                  display="flex"
                  gap="16px"
                  textTransform="none"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box textAlign="start">
                    <Typography
                      fontSize="14px"
                      fontWeight={formData.level === levelName ? "600" : 400}
                      color={
                        formData.level === levelName ? "#1976d2" : "#000"
                      }
                    >
                      {title}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            ))}
          </Box>
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <Typography
            ml={0.5}
            color={"#333333"}
            fontWeight="bold"
            fontSize="14px"
            htmlFor="title"
          >
            Day
          </Typography>
          <FormControl sx={{ mt: 1 }} fullWidth>
            <Select
              displayEmpty
              value={formData.day}
              onChange={(event) =>
                setFormData({ ...formData, day: event.target.value })
              }
            >
              <MenuItem style={{ color: "#9CA3AF" }} value="" disabled>
                Select day
              </MenuItem>
              {days?.map((item) => (
                <MenuItem
                  disabled={scheduledDays?.includes(item)}
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledTextFieldContainer>
        <StyledTextFieldContainer style={{ marginTop: "40px" }}>
          <StyledInputLabel htmlFor="title">Title</StyledInputLabel>
          <StyledTextField
            fullWidth
            variant="outlined"
            name="title"
            placeholder="Enter the title"
            value={formData.title}
            onChange={handleInputChange}
            required
            margin="normal"
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer style={{ marginTop: "40px" }}>
          <StyledInputLabel htmlFor="subtitle">
            Sub title (Optional)
          </StyledInputLabel>
          <StyledTextField
            fullWidth
            variant="outlined"
            name="subtitle"
            placeholder="Enter the Sub title"
            value={formData.subtitle}
            onChange={handleInputChange}
            margin="normal"
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <Typography
            ml={0.5}
            color={"#333333"}
            fontWeight="bold"
            fontSize="14px"
            htmlFor="title"
          >
            Upload Audio
          </Typography>
          <Box mt={1}>
            <input
              id="upload-input"
              type="file"
              accept=".mp3, .wav, .aac"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {formData.audio ? (
              <Box display="flex" gap={2} alignItems={"center"}>
                <Box
                  sx={{ background: "#FCFCFC" }}
                  p={"10px 20px"}
                  borderRadius={"100px"}
                  width={"100%"}
                >
                  <AudioPlayer
                    isSliderNeeded
                    src={audioSrc || formData.audio}
                  />
                </Box>
                <IconButton
                  onClick={() => setFormData({ ...formData, audio: "" })}
                  sx={{
                    fontSize: "16px",
                    borderRadius: "4px",
                    background: "#E94545",
                    height: "40px",
                    svg: {
                      path: {
                        fill: "#FCFCFC"
                      }
                    },
                    "&:hover": {
                      background: "#FF4000"
                    }
                  }}
                >
                  <Delete height={24} width={24} />
                </IconButton>
              </Box>
            ) : (
              <Box
                onClick={() => document.getElementById("upload-input").click()}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: 150,
                  border: "1px dashed #D1D5DB",
                  borderRadius: "4px",
                  position: "relative",
                  cursor: "pointer",
                  bgcolor: "#F9FAFB"
                }}
              >
                <MusicIcon />
                <Typography fontWeight="bold" color="#4B5563" mt={1}>
                  Tap to upload audio
                </Typography>
                <Typography fontSize="12px" color="#6B7280">
                  (Supported file MP3, AAC)
                </Typography>
              </Box>
            )}
          </Box>
        </StyledTextFieldContainer>
        <StyledTextFieldContainer style={{ marginTop: "50px" }}>
          <StyledInputLabel htmlFor="generalText">
            General Text
          </StyledInputLabel>
          <Box mt={6}>
            <Suspense fallback={<div>Loading...</div>}>
              <RichTextEditor
                defaultValue={editData?.generalText}
                value={value}
                setValue={setValue}
              />
            </Suspense>
          </Box>
        </StyledTextFieldContainer>{" "}
        <Box ml={2} gap={2} display="flex">
          <Button
            style={{
              textTransform: "none",
              color: "#6B7280",
              fontWeight: 700,
              padding: "12px 20.5px"
            }}
            onClick={() => {
              setIsAddingTraining(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!formData.title || (!editData && !formData.audio)}
            sx={{
              textTransform: "none",
              color: "#fcfcfc",
              padding: "12px 20.5px",
              background: "#1976d2",
              fontWeight: 700,
              "&:disabled": {
                backgroundColor: "#A7C8E9",
                color: "#FFF",
                pointerEvents: "none"
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
export default AddTraining;
