import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Box,
  Typography,
  LinearProgress,
  Chip,
  IconButton,
  Avatar,
  Tooltip,
  Card,
  Grid,
  Divider,
  alpha
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserDailyDiaries,
  getCurrentUserDiaryLevels
} from "../../features/diary/diarySlice";
import dayjs from "dayjs";
import {
  selectAllDiariesData,
  selectAllDiaryLevels
} from "../../features/diary/diarySelectors";

// Constants moved to their own section for better organization
const FOOD_OPTIONS = {
  foods800g: [
    { value: "veggies", label: "Veggies" },
    { value: "fruit", label: "Fruit" },
    { value: "avocado", label: "Avocado/olive" },
    { value: "leafygreen", label: "Leafy greens" },
    { value: "beans", label: "Beans/lentils" },
    { value: "banana", label: "Banana/potato" },
    { value: "potato", label: "Mashed items" },
    { value: "others", label: "Others" }
  ],
  proteins: [
    { value: "chicken", label: "Chicken" },
    { value: "dryfruit", label: "Dry Fruit" },
    { value: "milk", label: "Milk" },
    { value: "egg", label: "Egg" },
    { value: "others", label: "Others" }
  ]
};

// Color constants
const COLORS = {
  foods800g: "#F6541C", // red
  protein: "#368AD0", // blue
  calories: "#FF9431", // orange
  other: "#757575" // gray
};

// Helper functions
const formatDate = (dateString) => {
  return dayjs(dateString).format("MMM D, YYYY");
};

const calculateProgress = (consumed, total) => {
  return Math.min((consumed / total) * 100, 100); // Cap at 100%
};

const isToday = (dateString) => {
  return dayjs(dateString).isSame(dayjs(), "day");
};

// Modal Header Component
const ModalHeader = ({
  onClose,
  selectedLevel,
  setSelectedLevel,
  allLevels
}) => {
  const theme = useTheme();

  return (
    <DialogTitle
      sx={{
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 3,
        px: 4,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          sx={{
            bgcolor: "rgba(255,255,255,0.2)",
            mr: 2,
            width: 48,
            height: 48,
            boxShadow: "0 2px 10px rgba(0,0,0,0.2)"
          }}
        >
          <RestaurantIcon sx={{ fontSize: 26 }} />
        </Avatar>
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: "700", letterSpacing: "0.5px" }}
          >
            Diary Logs
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <FormControl
          size="small"
          sx={{
            minWidth: 150,
            bgcolor: "rgba(255,255,255,0.15)",
            borderRadius: 2,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(255,255,255,0.3)"
            },
            "& .MuiSelect-select": {
              color: "white"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(255,255,255,0.5)"
            },
            transition: "all 0.3s ease"
          }}
        >
          <Select
            value={selectedLevel || ""}
            onChange={(e) => setSelectedLevel(e.target.value)}
            sx={{
              color: "white",
              borderRadius: 2,
              "& .MuiSelect-icon": { color: "white" }
            }}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography sx={{ color: "rgba(255,255,255,0.7)" }}>
                    Select Level
                  </Typography>
                );
              }
              const selectedLevelData = allLevels?.find(
                (item) => item.levelId === selected
              );
              return selectedLevelData?.title || "Select Level";
            }}
          >
            {allLevels?.map((item) => (
              <MenuItem key={item?.levelId} value={item?.levelId}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {item.title}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title="Close">
          <IconButton
            onClick={onClose}
            sx={{
              color: "white",
              bgcolor: "rgba(255,255,255,0.1)",
              "&:hover": {
                bgcolor: "rgba(255,255,255,0.2)"
              },
              transition: "all 0.2s ease"
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </DialogTitle>
  );
};

// Legend Component
const ColorLegend = () => (
  <Box
    sx={{
      display: "flex",
      gap: 3,
      flexWrap: "wrap",
      justifyContent: { xs: "center", md: "flex-end" },
      mb: { xs: 2, md: 0 }
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: "50%",
          bgcolor: COLORS.foods800g,
          mr: 1
        }}
      />
      <Typography variant="body2" color="text.secondary" fontWeight="500">
        800g
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: "50%",
          bgcolor: COLORS.protein,
          mr: 1
        }}
      />
      <Typography variant="body2" color="text.secondary" fontWeight="500">
        Protein
      </Typography>
    </Box>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: "50%",
          bgcolor: COLORS.calories,
          mr: 1
        }}
      />
      <Typography variant="body2" color="text.secondary" fontWeight="500">
        Calories
      </Typography>
    </Box>
  </Box>
);

// Progress Bar Component
const NutritionProgressBar = ({
  value,
  color,
  label,
  consumed,
  total,
  unit = "g"
}) => (
  <Box sx={{ width: "100%", mb: 1.5 }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
        alignItems: "center"
      }}
    >
      <Typography variant="caption" fontWeight="600" sx={{ color }}>
        {label}
      </Typography>
      <Typography variant="caption" fontWeight="600" sx={{ color }}>
        {`${consumed}/${total}${unit}`}
      </Typography>
    </Box>
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: 8,
        borderRadius: 4,
        background: `${color}20`,
        "& .MuiLinearProgress-bar": {
          bgcolor: color,
          borderRadius: 4,
          transition: "transform 0.5s ease"
        }
      }}
    />
  </Box>
);

// Food Chip Component
const FoodChip = ({ food, color, options }) => {
  const quantityText = food.noOfQty
    ? `${Math.round(food.noOfQty)} pieces`
    : `${Math.round(food.quantity || 0)} ${food.unit || ""}`;

  const foodLabel =
    options?.find((option) => option.value === food?.name)?.label ?? food?.name;

  return (
    <Chip
      label={
        <Typography
          component="span"
          sx={{
            fontSize: "0.75rem",
            fontWeight: 500,
            textTransform: "capitalize"
          }}
        >
          {foodLabel}{" "}
          <Typography
            component="span"
            sx={{
              fontSize: "0.75rem",
              fontWeight: 600,
              color,
              ml: 0.5
            }}
          >
            ({quantityText})
          </Typography>
        </Typography>
      }
      size="small"
      sx={{
        borderRadius: 2,
        fontWeight: 500,
        "& .MuiChip-label": { px: 1.2 },
        border: `1px solid ${color}`,
        color,
        transition: "all 0.2s ease",
        "&:hover": {
          backgroundColor: alpha(color, 0.1),
          boxShadow: `0 2px 4px ${alpha(color, 0.2)}`
        }
      }}
      variant="outlined"
    />
  );
};

// Food List Component
const FoodList = ({ items, color, options }) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 1,
      justifyContent: "center",
      py: 0.5
    }}
  >
    {items && items.length > 0 ? (
      items.map((food, idx) => (
        <FoodChip
          key={food._id || idx}
          food={food}
          color={color}
          options={options}
        />
      ))
    ) : (
      <Typography
        variant="body2"
        color="text.secondary"
        fontStyle="italic"
        sx={{ textAlign: "center", width: "100%", py: 1 }}
      >
        No items logged
      </Typography>
    )}
  </Box>
);

// Table Row Component
const DiaryTableRow = ({ entry, isLevel1, isLevel3, theme }) => {
  const progress800g = calculateProgress(entry.totalEntry800g, entry.outOf800g);
  const progressProtein = calculateProgress(
    entry.totalProtein,
    entry.outOfProtein
  );
  const progressCalories = calculateProgress(
    entry.totalCalories || 0,
    entry.outOfCalories || 2000
  );

  return (
    <TableRow
      hover
      sx={{
        "&:nth-of-type(even)": { bgcolor: "rgba(0,0,0,0.01)" },
        borderLeft: isToday(entry.currentDayDate)
          ? `4px solid ${theme.palette.primary.main}`
          : "none",
        transition: "background-color 0.2s ease",
        "&:hover": {
          bgcolor: "rgba(25, 118, 210, 0.04)"
        }
      }}
    >
      <TableCell>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" fontWeight="medium">
            {formatDate(entry.currentDayDate)}
            {isToday(entry.currentDayDate) && (
              <Chip
                label="Today"
                size="small"
                color="primary"
                sx={{
                  ml: 1,
                  height: 20,
                  fontSize: "0.65rem",
                  fontWeight: "bold",
                  boxShadow: `0 2px 4px ${alpha(
                    theme.palette.primary.main,
                    0.3
                  )}`
                }}
              />
            )}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {dayjs(entry.currentDayDate).format("dddd")}
          </Typography>
        </Box>
      </TableCell>

      <TableCell sx={{ py: 2.5 }}>
        <NutritionProgressBar
          value={progress800g}
          color={COLORS.foods800g}
          label="800g"
          consumed={entry.totalEntry800g}
          total={entry.outOf800g}
          unit="g"
        />

        {!isLevel1 && (
          <NutritionProgressBar
            value={progressProtein}
            color={COLORS.protein}
            label="Protein"
            consumed={entry.totalProtein}
            total={entry.outOfProtein}
            unit="g"
          />
        )}

        {isLevel3 && (
          <NutritionProgressBar
            value={progressCalories}
            color={COLORS.calories}
            label="Calories"
            consumed={Math.round(entry.totalCalories || 0)}
            total={entry.outOfCalories || 2000}
            unit="kcal"
          />
        )}
      </TableCell>

      <TableCell>
        <FoodList
          items={entry.entry800g}
          color={COLORS.foods800g}
          options={FOOD_OPTIONS.foods800g}
        />
      </TableCell>

      {!isLevel1 && (
        <TableCell>
          <FoodList
            items={entry.protein}
            color={COLORS.protein}
            options={FOOD_OPTIONS.proteins}
          />
        </TableCell>
      )}

      {isLevel3 && (
        <TableCell>
          <FoodList items={entry.otherFood} color={COLORS.other} />
        </TableCell>
      )}
    </TableRow>
  );
};

// Main Dialog Content Component
const DialogContentComponent = ({ userDiaryData, currentLevel, theme }) => {
  const isLevel1 = currentLevel?.levelName === "level1";
  const isLevel2 = currentLevel?.levelName === "level2";
  const isLevel3 = currentLevel?.levelName === "level3";

  return (
    <DialogContent
      sx={{
        p: 0,
        bgcolor: "#f9fafc",
        backgroundImage: "linear-gradient(to bottom, #f9fafc, #f5f7fa)"
      }}
    >
      <Box sx={{ px: 4, pt: 3, pb: 4 }}>
        {/* Daily Breakdown Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "center" },
            mb: 2.5,
            gap: 2
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              position: "relative"
            }}
          >
            Daily Breakdown
          </Typography>

          <ColorLegend />
        </Box>

        <TableContainer
          component={Paper}
          elevation={3}
          sx={{
            borderRadius: 3,
            overflow: "auto",
            backgroundColor: "white",
            maxWidth: "100%",
            boxShadow: "0 4px 20px rgba(0,0,0,0.06)",
            "&::-webkit-scrollbar": {
              height: 8,
              width: 8
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              borderRadius: 4
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: alpha(theme.palette.primary.main, 0.05)
            }
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  background: `linear-gradient(to right, ${alpha(
                    theme.palette.primary.main,
                    0.05
                  )}, ${alpha(theme.palette.primary.main, 0.02)})`
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    py: 2.5,
                    width: "15%",
                    textAlign: "center",
                    borderBottom: `2px solid ${alpha(
                      theme.palette.primary.main,
                      0.2
                    )}`
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    py: 2.5,
                    width: "20%",
                    textAlign: "center",
                    borderBottom: `2px solid ${alpha(
                      theme.palette.primary.main,
                      0.2
                    )}`
                  }}
                >
                  Progress
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    py: 2.5,
                    width: "35%",
                    textAlign: "center",
                    borderBottom: `2px solid ${alpha(
                      theme.palette.primary.main,
                      0.2
                    )}`
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <LocalDiningIcon
                      sx={{ color: COLORS.foods800g, mr: 1, fontSize: 20 }}
                    />
                    800g
                  </Box>
                </TableCell>
                {!isLevel1 && (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      py: 2.5,
                      width: "30%",
                      textAlign: "center",
                      borderBottom: `2px solid ${alpha(
                        theme.palette.primary.main,
                        0.2
                      )}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <FitnessCenterIcon
                        sx={{ color: COLORS.protein, mr: 1, fontSize: 20 }}
                      />
                      Protein
                    </Box>
                  </TableCell>
                )}
                {isLevel3 && (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      py: 2.5,
                      textAlign: "center",
                      borderBottom: `2px solid ${alpha(
                        theme.palette.primary.main,
                        0.2
                      )}`
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <RestaurantIcon
                        sx={{ color: COLORS.other, mr: 1, fontSize: 20 }}
                      />
                      Other Foods
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {userDiaryData && userDiaryData.length > 0 ? (
                userDiaryData.map((entry, index) => (
                  <DiaryTableRow
                    key={entry._id || index}
                    entry={entry}
                    isLevel1={isLevel1}
                    isLevel3={isLevel3}
                    theme={theme}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={isLevel3 ? 5 : isLevel1 ? 3 : 4}
                    align="center"
                    sx={{ py: 4 }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      No diary entries available.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DialogContent>
  );
};

// Main component
const NutritionTrackingModal = ({ open, onClose, userData }) => {
  const [selectedLevel, setSelectedLevel] = useState(userData?.diaryLevelId);
  const theme = useTheme();
  const allLevels = useSelector(selectAllDiaryLevels);
  const userDiaryData = useSelector(selectAllDiariesData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData?.diaryLevelId && allLevels?.length > 0) {
      // Ensure the diaryLevelId exists in the available levels
      const levelExists = allLevels.some(
        (level) => level.levelId === userData.diaryLevelId
      );
      if (levelExists) {
        setSelectedLevel(userData.diaryLevelId);
      } else if (allLevels.length > 0) {
        // Fallback to the first available level if user's level is not found
        setSelectedLevel(allLevels[0].levelId);
      }
    }
  }, [userData?.diaryLevelId, allLevels]);

  useEffect(() => {
    if (userData?.userId) {
      dispatch(
        getCurrentUserDiaryLevels({
          date: dayjs().format("YYYY-MM-DD"),
          userId: userData?.userId
        })
      );
      setSelectedLevel(userData?.diaryLevelId);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (userData?.userId && selectedLevel)
      dispatch(
        getCurrentUserDailyDiaries({
          levelId: selectedLevel,
          userId: userData?.userId
        })
      );
  }, [dispatch, selectedLevel, userData]);

  const currentLevel = allLevels?.find(
    (item) => item.levelId === selectedLevel
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          minHeight: "85vh",
          overflow: "hidden",
          margin: { xs: "10px", sm: "auto" },
          width: { xs: "calc(100% - 20px)", sm: "auto" },
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)"
        }
      }}
    >
      <ModalHeader
        onClose={onClose}
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
        allLevels={allLevels}
      />

      <DialogContentComponent
        userDiaryData={userDiaryData}
        currentLevel={currentLevel}
        theme={theme}
      />
    </Dialog>
  );
};

// Required hook for theme
function useTheme() {
  return {
    palette: {
      primary: {
        main: "#1976D2",
        dark: "#0d47a1"
      }
    }
  };
}

export default NutritionTrackingModal;
