import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Backdrop,
  Fade,
  Stack
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PaymentIcon from "@mui/icons-material/Payment";
import { useNavigate } from "react-router-dom";

export const FailedPaymentModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const handleRetry = () => {
    onClose(); // Close the modal after handling retry
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      sx={{ zIndex: 10000 }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
            textAlign: "center"
          }}
        >
          <ErrorOutlineIcon
            sx={{
              fontSize: 60,
              color: "error.main",
              mb: 2
            }}
          />
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              mb: 1
            }}
          >
            Payment Issue Detected
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              color: "text.secondary"
            }}
          >
            Your recent payment attempt was unsuccessful. To ensure
            uninterrupted access to our services, please update your payment
            method and try again with a different card.
            <br />
            <br />
            If the payment is not completed within 14 days, your account may be
            deactivated, and you could lose access to your users. Please update
            your payment details as soon as possible.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleRetry}
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                textTransform: "capitalize",
                px: 4
              }}
            >
              <PaymentIcon sx={{ mr: 1 }} />
              Update Payment
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};
