import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  Chip,
  List,
  ListItem,
  Avatar,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import {
  AttachMoney,
  Info,
  CheckCircle,
  Cancel,
  ArrowDownward
} from "@mui/icons-material";
import CardLists from "../Extra/CardsList";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSuperAdmin,
  selectSubAdminBillingDetailsdData
} from "../../features/auth/authSelectors";
import {
  getCurrentUser,
  getSubAdminDashboardDetails
} from "../../features/auth/authSlice";
import { downgradeSubscription } from "../../api/authApi";
const gradientBg =
  "linear-gradient(135deg, rgba(54, 162, 235, 0.03), rgba(75, 192, 192, 0.01))";
const userGradientBg = "linear-gradient(135deg, #ff980008, #ff980002)";
const borderColor = "1px solid rgba(54, 162, 235, 0.08)";

const paperProps = {
  elevation: 1,
  sx: {
    p: 3,
    borderRadius: 4,
    background: gradientBg,
    border: borderColor,
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0 12px 32px rgba(0,0,0,0.08)"
    }
  }
};

const userPaperProps = {
  elevation: 1,
  sx: {
    p: 3,
    borderRadius: 4,
    background: userGradientBg,
    border: borderColor,
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0 12px 32px rgba(0,0,0,0.08)"
    }
  }
};
const AdminDashboard = () => {
  const currentAdmin = useSelector(selectCurrentSuperAdmin);
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectSubAdminBillingDetailsdData);
  useEffect(() => {
    dispatch(getSubAdminDashboardDetails());
  }, [dispatch]);
  const isNotAffiliateAdmin = currentAdmin?.userType !== "Affiliate";
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleDowngrade = async () => {
    await downgradeSubscription({
      amount: dashboardData?.downgradeOptions?.amount,
      limit: dashboardData?.downgradeOptions?.limit,
      userType: currentAdmin?.userType?.toLowerCase(),
      stripeCustomerId: currentAdmin?.stripeCustomerId,
      referenceId: currentAdmin?.referenceId
    });
    dispatch(getSubAdminDashboardDetails());
    dispatch(getCurrentUser());
    handleCloseModal();
  };

  return (
    <Box>
      {!isNotAffiliateAdmin ? (
        <></>
      ) : (
        <>
          {" "}
          <CardLists />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              my: 3,
              p: 3,
              backgroundColor: "rgb(248 246 249)",
              borderRadius: 2,
              boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
              alignItems: "center",
              position: "relative"
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#e91e63",
                mr: 2,
                width: 40,
                height: 40,
                fontSize: 20,
                color: "#ffffff"
              }}
            >
              T
            </Avatar>
            <Stack
              spacing={2}
              display={"flex"}
              flexDirection={"row"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack spacing={0.5}>
                <Typography
                  fontWeight="bold"
                  sx={{
                    color: "#e91e63",
                    fontSize: "1.1rem"
                  }}
                >
                  Current Tier
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#7f8c8d",
                    fontSize: "0.9rem"
                  }}
                >
                  0 - {currentAdmin?.referenceData?.maxSeats} Users
                </Typography>
              </Stack>
              <Box display="flex" gap="10px">
                {dashboardData?.downgradeOptions && (
                  <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<ArrowDownward />}
                    onClick={handleOpenModal}
                    sx={{
                      mt: 2,
                      textTransform: "none",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "#fff3e0"
                      }
                    }}
                  >
                    Downgrade Plan
                  </Button>
                )}{" "}
                <Tooltip
                  title={`Your current subscription allows up to ${currentAdmin?.referenceData?.maxSeats} users.`}
                >
                  <IconButton
                    size="small"
                    sx={{ position: "absolute", top: 10, right: 10 }}
                  >
                    <Info />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper {...paperProps}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box
                      sx={{
                        p: 1.5,
                        borderRadius: 2,
                        bgcolor: "#36a2eb",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <AttachMoney sx={{ fontSize: 24, color: "white" }} />
                    </Box>
                    <Typography variant="h6" fontWeight="bold" color="#36a2eb">
                      Billing History
                    </Typography>
                  </Stack>
                </Stack>
                <List sx={{ width: "100%", p: 0 }}>
                  {dashboardData?.subAdminInvoices?.map(
                    (transaction, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          py: 2,
                          px: 3,
                          my: 1,
                          borderRadius: 2,
                          bgcolor: "white",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.02)",
                          transition: "all 0.2s ease-in-out",
                          "&:hover": {
                            background: gradientBg,
                            transform: "translateY(-2px)"
                          }
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            sx={{ flex: 1, color: "#2c3e50" }}
                          >
                            {transaction?.product?.[0]?.description}
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              variant="body2"
                              sx={{ color: "#7f8c8d", minWidth: "80px" }}
                            >
                              {transaction?.product?.[0]?.purchaseDate}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color:
                                  transaction.status === "paid"
                                    ? "#27ae60"
                                    : transaction.status === "failed"
                                    ? "#e74c3c"
                                    : "#ff9800",
                                fontWeight: 500,
                                minWidth: "80px"
                              }}
                            >
                              ${transaction.amount.toFixed(2)}
                            </Typography>
                            <Chip
                              label={
                                transaction.status === "paid"
                                  ? "Success"
                                  : transaction.status === "failed"
                                  ? "Failed"
                                  : "Open"
                              }
                              icon={
                                transaction.status === "paid" ? (
                                  <CheckCircle sx={{ fontSize: 16 }} />
                                ) : transaction.status === "failed" ? (
                                  <Cancel sx={{ fontSize: 16 }} />
                                ) : (
                                  <Info sx={{ fontSize: 16 }} />
                                )
                              }
                              sx={
                                transaction.status === "paid"
                                  ? {
                                      bgcolor: "#eafaf1",
                                      color: "#27ae60",
                                      fontWeight: 500
                                    }
                                  : transaction.status === "failed"
                                  ? {
                                      bgcolor: "#ffeaea",
                                      color: "#e74c3c",
                                      fontWeight: 500
                                    }
                                  : {
                                      bgcolor: "#fff4e5",
                                      color: "#ff9800",
                                      fontWeight: 500
                                    }
                              }
                            />
                          </Stack>
                        </Stack>
                      </ListItem>
                    )
                  )}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container mt={2} spacing={3}>
        <Grid pl={"30px"} item xs={12}>
          <Paper {...userPaperProps}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    bgcolor: "#ff9800",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <AttachMoney sx={{ fontSize: 24, color: "white" }} />
                </Box>
                <Typography display={"flex"} gap="10px" alignItems="center">
                  <Typography variant="h6" fontWeight="bold" color="#ff9800">
                    User Transactions{" "}
                  </Typography>
                  <Typography color="red">
                    {!["Company"].includes(currentAdmin?.userType)
                      ? "($ values shown are still subject to % split)"
                      : ""}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
            <List sx={{ width: "100%", p: 0 }}>
              {dashboardData?.userTransactions?.map((transaction, index) => (
                <ListItem
                  key={index}
                  sx={{
                    py: 2,
                    px: 3,
                    my: 1,
                    borderRadius: 2,
                    bgcolor: "white",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.02)",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      background: userGradientBg,
                      transform: "translateY(-2px)"
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{ flex: 1, color: "#2c3e50" }}
                    >
                      {transaction?.product?.[0]?.description}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography
                        variant="body2"
                        sx={{ color: "#7f8c8d", minWidth: "80px" }}
                      >
                        {transaction?.product?.[0]?.purchaseDate}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            transaction.status === "paid"
                              ? "#27ae60"
                              : transaction.status === "failed"
                              ? "#e74c3c"
                              : "#ff9800",
                          fontWeight: 500,
                          minWidth: "80px"
                        }}
                      >
                        ${transaction.amount.toFixed(2)}
                      </Typography>
                      <Chip
                        label={
                          transaction.status === "paid"
                            ? "Success"
                            : transaction.status === "failed"
                            ? "Failed"
                            : "Open"
                        }
                        icon={
                          transaction.status === "paid" ? (
                            <CheckCircle sx={{ fontSize: 16 }} />
                          ) : transaction.status === "failed" ? (
                            <Cancel sx={{ fontSize: 16 }} />
                          ) : (
                            <Info sx={{ fontSize: 16 }} />
                          )
                        }
                        sx={
                          transaction.status === "paid"
                            ? {
                                bgcolor: "#eafaf1",
                                color: "#27ae60",
                                fontWeight: 500
                              }
                            : transaction.status === "failed"
                            ? {
                                bgcolor: "#ffeaea",
                                color: "#e74c3c",
                                fontWeight: 500
                              }
                            : {
                                bgcolor: "#fff4e5",
                                color: "#ff9800",
                                fontWeight: 500
                              }
                        }
                      />
                    </Stack>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ bgcolor: "#fff3e0", color: "#ed6c02" }}>
          Confirm Plan Downgrade
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <Typography variant="body1">
              Are you sure you want to downgrade your plan?
            </Typography>
            <Box sx={{ bgcolor: "#f5f5f5", p: 2, borderRadius: 1 }}>
              <Stack spacing={1}>
                <Typography variant="subtitle2">
                  Next Charge: ${dashboardData?.downgradeOptions?.amount}
                </Typography>
                <Typography variant="subtitle2">
                  New User Limit: {dashboardData?.downgradeOptions?.limit} users
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleCloseModal} variant="outlined" color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleDowngrade}
            variant="contained"
            color="warning"
            sx={{ ml: 1 }}
          >
            Confirm Downgrade
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminDashboard;
