import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  completeDiary,
  getAllUserDiaries,
  getAllDiaryUserLevels,
  getAllDiaryUserStats,
  getLeaderBoardData,
  getAllCustomFoodOptions,
  getAllUserIndividualDiaries
} from "../../api/diaryApi";

export const getCurrentUserDiaries = createAsyncThunk(
  "diary/getCurrentUserDiaries",
  async ({ levelId, date, userId }, thunkAPI) => {
    try {
      const response = await getAllUserDiaries(levelId, date, userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCurrentUserDailyDiaries = createAsyncThunk(
  "diary/getCurrentUserDiaries",
  async ({ levelId, userId }, thunkAPI) => {
    try {
      const response = await getAllUserIndividualDiaries(levelId, userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const completeDiaryDay = createAsyncThunk(
  "diary/completeDiaryDay",
  async (data, thunkAPI) => {
    try {
      const response = await completeDiary(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserDiaryLevels = createAsyncThunk(
  "diary/getCurrentUserDiaryLevels",
  async ({ date, userId }, thunkAPI) => {
    try {
      const response = await getAllDiaryUserLevels(date, userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentUserDiaryStats = createAsyncThunk(
  "diary/getCurrentUserDiaryStats",
  async (data, thunkAPI) => {
    try {
      const response = await getAllDiaryUserStats(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentLevelLeaderboard = createAsyncThunk(
  "diary/getCurrentLevelLeaderboard",
  async ({ levelId, month, year }, thunkAPI) => {
    try {
      const response = await getLeaderBoardData(levelId, month, year);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const gettingAllCustomFoodOptions = createAsyncThunk(
  "diary/gettingAllCustomFoodOptions",
  async (type, thunkAPI) => {
    try {
      const response = await getAllCustomFoodOptions(type);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Initial state
const initialState = {
  allDiaries: [],
  availableDiaryLevels: [],
  options800gms: [],
  optionsProteins: [],
  extraData: {},
  customFoodOptions: [],
  stats: [],
  outOf800g: "800",
  outOfProtein: "100",
  outOfCalories: "2000",
  leaderboard: [],
  loading: false, // loading state for getCurrentUserLevels
  error: null // error state
};

const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserDiaryLevels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaryLevels.fulfilled, (state, action) => {
        state.loading = false;
        state.availableDiaryLevels = action.payload.diaryLevels; // assuming response has meals data
      })
      .addCase(getCurrentUserDiaryLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(gettingAllCustomFoodOptions.fulfilled, (state, action) => {
        state.customFoodOptions = action.payload.data;
      })
      .addCase(gettingAllCustomFoodOptions.rejected, (state, action) => {
        state.customFoodOptions = [];
        state.error = action.payload;
      })
      .addCase(getCurrentUserDailyDiaries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDailyDiaries.fulfilled, (state, action) => {
        state.loading = false;
        state.allDiaries = action.payload.diaryLogs; // assuming response has meals data
      })
      .addCase(getCurrentUserDailyDiaries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUserDiaryStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUserDiaryStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload.diaries; // assuming response has meals data
        state.outOf800g = action.payload.outOf800g;
        state.outOfProtein = action.payload.outOfProtein;
        state.outOfCalories = action.payload.outOfCalories;
        state.extraData = {
          averagedSummaries: action.payload?.averagedSummaries,
          totalSummaries: action.payload?.totalSummaries
        }; // assuming response has meals data
        state.statcounts = {
          entry800gCount: action.payload?.count800g,
          proteinCount: action.payload?.countProtein,
          caloriesCount: action.payload?.countCalories
        };
      })
      .addCase(getCurrentUserDiaryStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentLevelLeaderboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentLevelLeaderboard.fulfilled, (state, action) => {
        state.loading = false;
        state.leaderboard = action.payload.leaderboard; // assuming response has meals data
      })
      .addCase(getCurrentLevelLeaderboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// export const { updateTrainingById, updatePercentage } = diarySlice.actions;

export default diarySlice.reducer;
