import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers";
import useThemeColor from "../../utils/useTheme";
import { Alert, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";

function DateRangePicker({
  existingRanges,
  optionValue,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  currentLevel,
  currentRecordId,
  setHasErrors
}) {
  const styles = useThemeColor();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Get required days based on level
  const getRequiredDays = () => {
    if (currentLevel === "level1" || currentLevel === "level2") return 30;
    if (currentLevel === "level3") return 40;
    return 30; // default fallback
  };

  const normalizeDate = (date) => date && date.format("MM-DD");

  const doRangesOverlap = (start1, end1, start2, end2) => {
    return start1 <= end2 && end1 >= start2;
  };
  const currentAdmin = useSelector((state) => state.adminauth.admin);
  // Check if a date is within any existing range
  const isDateDisabled = (date) => {
    const normalizedDate = normalizeDate(date);
    return existingRanges.some(({ startDate, endDate, level, id }) => {
      const normalizedStart = normalizeDate(dayjs(startDate));
      const normalizedEnd = normalizeDate(dayjs(endDate));
      if (id === currentRecordId) return false;
      return (
        level === currentLevel &&
        normalizedStart &&
        normalizedEnd &&
        normalizedDate >= normalizedStart &&
        normalizedDate <= normalizedEnd
      );
    });
  };

  const validateDateRange = (newStartDate, newEndDate) => {
    if (!newStartDate || !newEndDate) return true;

    return !existingRanges.some(({ startDate, endDate, level, id }) => {
      if (id === currentRecordId) return false;
      if (level !== currentLevel) return false;

      const existingStart = dayjs(startDate);
      const existingEnd = dayjs(endDate);

      return doRangesOverlap(
        newStartDate.startOf("day"),
        newEndDate.startOf("day"),
        existingStart.startOf("day"),
        existingEnd.startOf("day")
      );
    });
  };

  const handleStartDateChange = (newStartDate) => {
    if (isDateDisabled(newStartDate)) {
      setSnackbarMessage("This start date overlaps with an existing record.");
      setOpenSnackbar(true);
      setHasErrors(true);
      return;
    }

    const requiredDays = getRequiredDays();
    const autoEndDate = newStartDate.add(requiredDays - 1, "day");

    if (!validateDateRange(newStartDate, autoEndDate)) {
      setSnackbarMessage(
        `Unable to set start date - ${requiredDays} day challenge would overlap with existing record.`
      );
      setOpenSnackbar(true);
      setHasErrors(true);
      return;
    }

    setStartDate(newStartDate);
    if (currentAdmin?.userType !== "Coach" && optionValue === "BOTH") {
      setEndDate(autoEndDate);
      setSnackbarMessage(`Challenge duration set to ${requiredDays} days`);
      setOpenSnackbar(true);
    } else {
      setEndDate(null);
    }
    setHasErrors(false);
  };

  const handleEndDateChange = (newEndDate) => {
    if (startDate && optionValue === "BOTH") {
      const requiredDays = getRequiredDays();
      const correctEndDate = startDate.add(requiredDays - 1, "day");

      setEndDate(correctEndDate);
      setSnackbarMessage(
        `End date automatically set to maintain ${requiredDays}-day challenge duration`
      );
      setOpenSnackbar(true);
    } else {
      setEndDate(newEndDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          ".MuiFormLabel-root": {
            color: styles.inputLabelColor
          },
          ".MuiTextField-root": {
            border: `1px solid ${styles.lightGreyColor}`,
            borderRadius: "4px"
          },
          ".MuiInputBase-root": {
            input: {
              color: styles.color
            }
          }
        }}
      >
        <DatePicker
          label="Start Date"
          value={startDate}
          disablePast
          shouldDisableDate={isDateDisabled}
          onChange={handleStartDateChange}
          sx={{
            ".Mui-error": {
              "& fieldset": {
                borderColor: "rgb(203,203,203) !important"
              }
            },
            color: styles.color
          }}
          InputProps={{
            style: { color: styles.color, border: `1px solid ${styles.color}` }
          }}
          slots={{
            openPickerIcon: () => <CalendarIcon sx={{ color: styles.color }} />
          }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          disablePast
          disabled={
            currentAdmin?.userType !== "Coach" && optionValue === "BOTH"
          }
          onChange={handleEndDateChange}
          minDate={startDate}
          sx={{
            ".Mui-error": {
              "& fieldset": {
                borderColor: "rgb(203,203,203) !important"
              }
            },
            color: styles.color
          }}
          InputProps={{
            style: { color: styles.color }
          }}
          slots={{
            openPickerIcon: () => <CalendarIcon sx={{ color: styles.color }} />
          }}
        />
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="info"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
}

export default DateRangePicker;
