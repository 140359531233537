import createApi from "../utils/axiosInstance";

// Super admin login API
export const superAdminLogin = async (credentials) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/admin/login", credentials);

    if (response.data) {
      const { notActiveUser, user, token } = response.data;
      if (!notActiveUser) {
        // const hours = expiresIn?.split("h")[0];
        // const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
        localStorage.setItem("adminToken", token);
        localStorage.setItem("admin", JSON.stringify(user));
      }
      // localStorage.setItem("expirationTime", expirationTime);
    }

    return response.data;
  } catch (error) {
    console.error(
      "Login failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addBulkUserCsv = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post("/auth/admin/add-users-csv", data);

    return response.data;
  } catch (error) {
    console.error(
      "Adding bulk users failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const exportUserCsv = async (date) => {
  try {
    const api = createApi(true);
    const response = await api.get(`/admin/user/exports-users?date=${date}`, {
      responseType: "blob"
    });

    // Create a URL for the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "user_details.xlsx"); // File name
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response.data;
  } catch (error) {
    console.error(
      "Exporting users failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Admin Register API
export const adminRegister = async (userData) => {
  try {
    const api = createApi(false);
    const { role, mainData } = userData;
    const response = await api.post(`/auth/admin/register/${role}`, mainData);
    return response.data;
  } catch (error) {
    console.error(
      "Registration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const verifyIdentity = async (sessionData) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/admin/verify-identity", sessionData);
    if (response.data) {
      const { user, token } = response.data;
      localStorage.setItem("adminToken", token);
      localStorage.setItem("admin", JSON.stringify(user));
    }
    return response.data;
  } catch (error) {
    console.error(
      "Verify identity failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// User Register by admin API
export const addNewUserAdmin = async (userData) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/auth/admin/add-user`, userData);
    return response.data;
  } catch (error) {
    console.error(
      "New user registeration failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const addNewLevels = async (levelData) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/auth/admin/create-training-diary-levels`,
      levelData
    );
    return response.data;
  } catch (error) {
    console.error(
      "New level adding failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const editUserProfile = async (userData, userId) => {
  try {
    const api = createApi(false);
    const response = await api.post(`/user/editProfile/${userId}`, userData);
    // if (response.data.user) {
    //   const { user, expiresIn, token } = response.data;
    //   const hours = expiresIn?.split("h")[0];
    //   const expirationTime = new Date().getTime() + hours * 60 * 60 * 1000;
    //   localStorage.setItem("token", token);
    //   localStorage.setItem("user", JSON.stringify(user));
    //   localStorage.setItem("expirationTime", expirationTime);
    // }
    return response.data;
  } catch (error) {
    console.error(
      "Completing profile failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error.message;
  }
};

// Super admin logout API
export const superAdminLogout = async () => {
  try {
    // Remove token from localStorage on logout
    localStorage.removeItem("adminToken");
    localStorage.removeItem("admin");
    // localStorage.removeItem("expirationTime");
    return { message: "Successfully logout" };
  } catch (error) {
    console.error(
      "Logout failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

// Fetch authenticated user
export const fetchUser = async () => {
  try {
    const api = createApi(true); // Token required
    const response = await api.get("/admin/user/get-current-user");
    return response.data;
  } catch (error) {
    console.error(
      "Fetching user data failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getConnectedAccountURL = async (accountId) => {
  try {
    const api = createApi(true); // Token required
    const response = await api.get(
      `/admin/user/generate-remediation-link/${accountId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Generating url failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getDashboardData = async (date) => {
  try {
    const api = createApi(true); // Token required
    const response = await api.get(`/admin/home/get-home-details?date=${date}`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting dashboard failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getSubAdminDashboardData = async () => {
  try {
    const api = createApi(true); // Token required
    const response = await api.get(`/admin/billing/get-billing-details`);
    return response.data;
  } catch (error) {
    console.error(
      "Getting dashboard failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const downgradeSubscription = async (data) => {
  try {
    const api = createApi(true); // Token required
    const response = await api.post(
      `/admin/billing/downgrade-subscription`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Getting dashboard failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const forgetPassword = async (email) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/forgotPassword", email);

    localStorage.removeItem("adminToken");
    localStorage.removeItem("user");
    // localStorage.removeItem("expirationTime");
    return response.data;
  } catch (error) {
    console.error(
      "Forget password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const resetPassword = async (userData) => {
  try {
    const api = createApi(false);
    const response = await api.post("/auth/resetPassword", userData);
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateAdminDetails = async (data) => {
  try {
    const api = createApi(true);
    const response = await api.post("/auth/admin/edit-profile-notes", data);
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const fetchAllCards = async () => {
  try {
    const api = createApi(true);
    const response = await api.get("/payment/card/get-card");
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const createNewCard = async (cardData) => {
  try {
    const api = createApi(true);
    const response = await api.post("/payment/card/add-card", cardData);
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const updateCard = async (
  // cardData,
  cardId
) => {
  try {
    const api = createApi(true);
    const response = await api.post(
      `/payment/card/edit-card/${cardId}`
      // cardData
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const removeCard = async (cardId) => {
  try {
    const api = createApi(true);
    const response = await api.post(`/payment/card/remove-card/${cardId}`);
    return response.data;
  } catch (error) {
    console.log("error", error);
    console.error(
      "Reset Password failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};

export const getFreeUserAdmin = async () => {
  try {
    const api = createApi(true);
    const response = await api.get(`/auth/admin/register/check-is-superadmin`);
    return response.data;
  } catch (error) {
    console.error(
      "Get user failed:",
      error.response ? error.response.data : error.message
    );
    throw error.response ? error.response.data : error;
  }
};
