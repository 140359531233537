// deleteFile.js
import { deleteS3File } from "../api/userApi";

export const deleteFile = async (fileName, folder = "meal-pictures") => {
  try {
    await deleteS3File({ key: `${folder}/${fileName}` });
    return "File deleted successfully!";
  } catch (error) {
    console.error("Error deleting file:", error);
    throw new Error("File deletion failed");
  }
};
