import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { Box, IconButton, Typography, Slider } from "@mui/material";

const AudioPlayer = ({ src, isSliderNeeded }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  // Toggle play/pause
  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Update duration and handle events when audio is loaded
  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setCurrentTime(0);
      setIsPlaying(false);
    };

    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("ended", handleEnded);
      }
    };
  }, [src]);

  // Format time to mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  // Handle progress bar change
  const handleSliderChange = (event, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = newValue;
    setCurrentTime(newValue);
  };

  return (
    <Box display="flex" alignItems="center" gap="12px" width="100%">
      <IconButton
        sx={{
          borderRadius: "50%",
          background: "#1976d2",
          color: "#FCFCFC",
          ":disabled": {
            backgroundColor: "#A7C8E9",
            color: "#FFF",
            pointerEvents: "none"
          }
        }}
        onClick={togglePlay}
        disabled={!duration}
      >
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      {isSliderNeeded && (
        <Slider
          value={currentTime}
          max={duration}
          onChange={handleSliderChange}
          sx={{
            color: "#1976d2",
            flex: 1,
            height: 8,
            borderRadius: 4,
            "& .MuiSlider-thumb": {
              display: "none"
            },
            "& .MuiSlider-track": {
              backgroundColor: "#1976d2"
            },
            "& .MuiSlider-rail": {
              backgroundColor: "#A7C8E9"
            }
          }}
        />
      )}
      <Typography fontSize="small">
        {formatTime(isPlaying ? currentTime : duration)}
      </Typography>
      <audio ref={audioRef} src={src} />
    </Box>
  );
};

export default AudioPlayer;
