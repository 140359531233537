import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  DialogContentText,
  Collapse,
  Snackbar,
  Alert,
  ToggleButtonGroup,
  ToggleButton
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  EmojiEvents as TrophyIcon,
  KeyboardArrowDown as ExpandMoreIcon,
  KeyboardArrowUp as ExpandLessIcon
} from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import DateRangePicker from "./SimpleDateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllChallenges } from "../../features/users/userSlice";
import { selectUsersChallenges } from "../../features/users/userSelectors";
import {
  addnewChallenge,
  deleteChallengeHandler,
  editChallengeHandler
} from "../../api/userApi";
import LeaderboardModal from "../Modals/Leaderboard";
import { selectLevelDetails } from "../../features/training/trainingSelectors";
import { getAllLevelDetails } from "../../features/training/trainingSlice";
import useThemeColor from "../../utils/useTheme";
// import DateRangePicker from "./DateRangePicker";
dayjs.extend(utc);

const ChallengeTable = ({
  challenges,
  setSelectedChallenge,
  handleEdit,
  handleDeleteClick,
  setOpenLeaderboardModal,
  level,
  isFromSubAdmin
}) => {
  const [expanded, setExpanded] = useState(true);

  const filteredChallenges = challenges
    .filter((challenge) => challenge.levelName === level.name)
    ?.map((item) => {
      let data = 0;
      item?.userPoints?.forEach((user) => {
        if (!item?.excluded_users?.includes(user?.userId)) {
          data++; // Increment count if userId is not found in idsToCheck
        }
      });
      return {
        ...item,
        totalUsers: data
      };
    });

  //   const getInitials = (name) => {
  //     return name
  //       .split(" ")
  //       .map((n) => n[0])
  //       .join("")
  //       .toUpperCase();
  //   };

  if (filteredChallenges.length === 0) return null;

  return (
    <Card sx={{ mb: 3, borderRadius: 2, overflow: "hidden" }}>
      <Box
        sx={{
          p: 2,
          bgcolor: "primary.main",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Typography
          textTransform={"capitalize"}
          variant="h6"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TrophyIcon sx={{ mr: 1 }} /> {level.title}
        </Typography>
        <IconButton
          onClick={() => setExpanded(!expanded)}
          sx={{ color: "white" }}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "primary.light"
                }}
              >
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Challenge Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Dates
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Length
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Participants
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  View Leaderboard
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "bold" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChallenges.map((challenge) => (
                <TableRow
                  key={challenge._id}
                  sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                >
                  <TableCell align="center">
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "medium" }}
                    >
                      {challenge.title}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {dayjs.utc(challenge.start_date).format("D MMM, YYYY")} -{" "}
                      {dayjs.utc(challenge.end_date).format("D MMM, YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {dayjs
                        .utc(challenge.end_date)
                        .diff(dayjs.utc(challenge.start_date), "days") + 1}{" "}
                      Days
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">
                      {challenge?.totalUsers || 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    // sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {/* <AvatarGroup max={4} sx={{ justifyContent: "flex-start" }}>
                      {challenge.allowedUsers.map((user) => (
                        <Avatar
                          key={user.id}
                          src={user.avatar}
                          alt={user.name}
                          sx={{ bgcolor: "secondary.main" }}
                        >
                          {!user.avatar && getInitials(user.name)}
                        </Avatar>
                      ))}
                    </AvatarGroup> */}
                    <Button
                      sx={{
                        border: "none"
                      }}
                      onClick={() => {
                        setSelectedChallenge(challenge);
                        setOpenLeaderboardModal(true);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(challenge)}
                      sx={{ "&:hover": { backgroundColor: "primary.light" } }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(challenge)}
                      sx={{ "&:hover": { backgroundColor: "error.light" } }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Card>
  );
};

const ChallengeManagement = ({ isFromSubAdmin = false }) => {
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [openLeaderboardModal, setOpenLeaderboardModal] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const challengeData = useSelector(selectUsersChallenges);
  const availableLevels = useSelector(selectLevelDetails) || [];

  const currentAdmin = useSelector((state) => state.adminauth.admin);

  useEffect(() => {
    dispatch(
      fetchAllChallenges(isFromSubAdmin ? currentAdmin?.referenceId : "")
    );
  }, [dispatch]);

  const [formData, setFormData] = useState({
    name: "",
    level: "level1",
    dateRange: [null, null],
    allowedUsers: []
  });
  const [editMode, setEditMode] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  useEffect(() => {
    dispatch(getAllLevelDetails());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false);
    resetForm();
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      name: "",
      level: "level1",
      dateRange: [null, null],
      allowedUsers: []
    });
    setSelectedChallenge(null);
    setEditMode(false);
    setStartDate(null);
    setEndDate(null);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);
      if (hasErrors) return;
      if (!formData.name || !formData.level) return;
      if (editMode && selectedChallenge) {
        await editChallengeHandler({
          levelName: formData?.level,
          title: formData?.name,
          start_date: dayjs(startDate).format("YYYY-MM-DD"),
          end_date: dayjs(endDate).format("YYYY-MM-DD"),
          type: alignment,
          referenceId: currentAdmin?.referenceId || null,
          userType: currentAdmin?.userType || "Individual",

          id: selectedChallenge?._id
        });
        setSnackbarMessage("Challenge edited successfully");
      } else {
        await addnewChallenge({
          levelName: formData?.level,
          title: formData?.name,
          type: alignment,
          referenceId: currentAdmin?.referenceId || null,
          userType: currentAdmin?.userType || "Individual",
          start_date: dayjs(startDate).format("YYYY-MM-DD"),
          end_date: dayjs(endDate).format("YYYY-MM-DD")
        });
        setSnackbarMessage("Challenge created successfully");
      }
      setOpenSnackbar(true);

      dispatch(
        fetchAllChallenges(isFromSubAdmin ? currentAdmin?.referenceId : "")
      );
      setHasErrors(false);
      handleClose();
    } catch (err) {
      setSnackbarMessage(err?.message || "Create/Edit challenge failed.");
      setOpenSnackbar(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleEdit = (challenge) => {
    setSelectedChallenge(challenge);
    setAlignment(challenge.type || "BOTH");
    setFormData({
      name: challenge.title,
      level: challenge.levelName,
      // "Level " + challenge.levelName.slice(-1),
      dateRange: challenge.dateRange,
      allowedUsers: challenge.allowedUsers
    });
    setStartDate(dayjs(challenge.start_date));
    setEndDate(dayjs(challenge.end_date));
    setEditMode(true);
    setOpen(true);
  };

  const handleDeleteClick = (challenge) => {
    setSelectedChallenge(challenge);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    await deleteChallengeHandler({ id: selectedChallenge._id });
    setDeleteDialogOpen(false);
    setSelectedChallenge(null);
    dispatch(
      fetchAllChallenges(isFromSubAdmin ? currentAdmin?.referenceId : "")
    );
  };
  const styles = useThemeColor();
  const [alignment, setAlignment] = useState("BOTH");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment === "BOTH") {
      setStartDate(null);
      setEndDate(null);
    }
    console.log(newAlignment);
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 3
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
          sx={{
            borderRadius: 2,
            textTransform: "none",
            px: 3,
            py: 1,
            boxShadow: 3
          }}
        >
          Create Challenge
        </Button>
      </Box>

      {availableLevels?.map(({ levelName, title }) => (
        <ChallengeTable
          key={levelName}
          setSelectedChallenge={setSelectedChallenge}
          setOpenLeaderboardModal={setOpenLeaderboardModal}
          challenges={challengeData}
          handleEdit={handleEdit}
          handleDeleteClick={handleDeleteClick}
          level={{ name: levelName, title }}
          isFromSubAdmin={isFromSubAdmin}
        />
      ))}

      {/* Create/Edit Challenge Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          elevation: 24,
          sx: { borderRadius: 3 }
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "primary.main",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: 2
          }}
        >
          <TrophyIcon />
          {editMode ? "Edit Challenge" : "Create New Challenge"}
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}>
            <TextField
              fullWidth
              label="Challenge Name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />

            <FormControl fullWidth>
              <InputLabel>Pillar</InputLabel>
              <Select
                value={formData.level}
                label="Pillar"
                onChange={(e) => {
                  setFormData({ ...formData, level: e.target.value });
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                {availableLevels?.map((item) => (
                  <MenuItem value={item.levelName}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {currentAdmin?.userType === "Coach" || !isFromSubAdmin ? (
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                sx={{
                  backgroundColor: styles.lightGreyColor, // Black background
                  overflow: "hidden",
                  width: "100%",
                  margin: "0 0 16px 0"
                }}
              >
                <ToggleButton
                  value="BOTH"
                  aria-label="both"
                  sx={{
                    textTransform: "none",
                    fontWeight: 600,
                    // color: "#FCFCFC !important",
                    flex: 1,
                    "&.Mui-selected": {
                      color: "#FCFCFC !important",
                      backgroundColor: "#368AD0",
                      ":hover": {
                        backgroundColor: "#368AD0"
                      }
                    }
                  }}
                >
                  Diary Logging & Program Points
                </ToggleButton>
                <ToggleButton
                  value="DIARY"
                  aria-label="diary"
                  sx={{
                    fontWeight: 600,
                    flex: 1,
                    textTransform: "none",
                    // color: "#FCFCFC !important",
                    "&.Mui-selected": {
                      color: "#FCFCFC !important",
                      backgroundColor: "#368AD0",
                      ":hover": {
                        backgroundColor: "#368AD0"
                      }
                    }
                  }}
                >
                  Diary Logging Points Only
                </ToggleButton>
              </ToggleButtonGroup>
            ) : (
              <></>
            )}
            <DateRangePicker
              optionValue={alignment}
              setHasErrors={setHasErrors}
              existingRanges={challengeData?.map((item) => ({
                startDate: item.start_date,
                endDate: item.end_date,
                level: item.levelName,
                id: item._id
              }))}
              startDate={startDate}
              currentLevel={formData.level}
              setStartDate={setStartDate}
              endDate={endDate}
              currentRecordId={selectedChallenge?._id}
              setEndDate={setEndDate}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ borderRadius: 2 }}
            disabled={hasErrors || !formData?.name}
          >
            {editMode ? "Update Challenge" : "Create Challenge"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={1000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarMessage?.includes("success") ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <LeaderboardModal
        open={openLeaderboardModal}
        leaderboardData={challengeData}
        setOpen={setOpenLeaderboardModal}
        selectedChallenge={selectedChallenge}
        referenceId={currentAdmin?.referenceId}
        isFromSubAdmin={isFromSubAdmin}
      />
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          elevation: 24,
          sx: { borderRadius: 3 }
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>Delete Challenge</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the challenge "
            {selectedChallenge?.title}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setDeleteDialogOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChallengeManagement;
