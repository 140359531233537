import React from "react";
import { ReactComponent as ChefIcon } from "../../assets/chef.svg";
import { ReactComponent as Lvl1Icon } from "../../assets/lvl1.svg";
import { ReactComponent as Lvl2Icon } from "../../assets/lvl2.svg";
import { ReactComponent as Lvl3Icon } from "../../assets/lvl3.svg";
import { ReactComponent as CoachIcon } from "../../assets/coach-badge.svg";
import { ReactComponent as BananaIcon } from "../../assets/banana.svg";
import { ReactComponent as ConstantIcon } from "../../assets/constant.svg";
import { ReactComponent as BicepIcon } from "../../assets/bicep.svg";
import { Box, Typography } from "@mui/material";

const BadgeDisplay = ({ open }) => {
  // Helper function to determine which badges are active
  const getActiveBadges = () => {
    const badges = [];

    if (open?.approvedMealsCount) {
      badges.push({
        icon: <ChefIcon height={50} width={50} />,
        label: "Chef",
        count: open.approvedMealsCount
      });
    }

    if (open?.completedLevel?.at(-1) === "level1") {
      badges.push({
        icon: <Lvl1Icon height={50} width={50} />,
        label: "Training Lvl 1"
      });
    }

    if (open?.completedLevel?.at(-1) === "level2") {
      badges.push({
        icon: <Lvl2Icon height={50} width={50} />,
        label: "Training Lvl 2"
      });
    }

    if (open?.completedLevel?.at(-1) === "level3") {
      badges.push({
        icon: <Lvl3Icon height={50} width={50} />,
        label: "Training Lvl 3"
      });
    }

    if (open?.isAdmin && open?.userType === "Coach") {
      badges.push({
        icon: <CoachIcon height={50} width={50} />,
        label: "Coach"
      });
    }

    if (open?.maxStreak) {
      badges.push({
        icon: <ConstantIcon height={50} width={50} />,
        label: "Streaking",
        count: open.maxStreak
      });
    }

    if (open?.isConsistent) {
      badges.push({
        icon: <BicepIcon height={50} width={50} />,
        label: "Consistency"
        // count: open.isConsistent
      });
    }
    if (open?.max800gStreak >= 30) {
      badges.push({
        icon: <BananaIcon height={50} width={50} />,
        label: "Secret"
        // count: open.isConsistent
      });
    }

    return badges;
  };

  const badges = getActiveBadges();

  // If no badges, return empty fragment
  if (badges.length === 0) return <></>;

  return (
    <Box
      display="flex"
      gap={0.4}
      alignItems="center"
      overflow="visible"
      flexWrap="nowrap"
      justifyContent="center"
    >
      {badges.map((badge, index) => (
        <Box
          key={index}
          display="flex"
          height={45}
          width={45}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="5px"
          position="relative"
        >
          {badge.icon}

          {badge.count && (
            <Typography
              color="#F6541C"
              borderRadius="50%"
              fontSize="0.6rem"
              sx={{
                padding:
                  String(badge.count)?.length === 1 ? "1px 7px" : "1px 4px",
                background: "#FCFCFC",
                position: "absolute",
                bottom: "7px",
                right: "5px",
                transform: "translate(50%, 50%)"
              }}
            >
              {badge.count}
            </Typography>
          )}
        </Box>
      ))}

      {/* {remainingBadges > 0 && (
        <Typography color="text.secondary" fontSize="0.75rem" sx={{ ml: 1 }}>
          +{remainingBadges} more
        </Typography>
      )} */}
    </Box>
  );
};

export default BadgeDisplay;
