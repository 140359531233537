export const suggestionData = {
  levels: {
    level1: {
      name: "level1",
      title: "#800gChallenge",
      description: "Unlock for $99"
    },
    level2: {
      name: "level2",
      title: "Lazy Macros",
      description: "Finish the #800gChallenge and then unlock for $99"
    },
    level3: {
      name: "level3",
      title: "Three Pillars Method",
      description: "Finish Lazy Macros and then unlock for $199"
    }
  },
  diaryLevels: {
    level1: {
      name: "level1",
      title: "#800gChallenge",
      description: "Pillar 1"
    },
    level2: {
      name: "level2",
      title: "Lazy Macros",
      description: "Pillar 2"
    },
    level3: {
      name: "level3",
      title: "Three Pillars Method",
      description: "Pillar 3"
    }
  }
};
