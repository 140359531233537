import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Container,
  Tooltip,
  alpha,
  styled,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { ReactComponent as AppleIcon } from "../../assets/apple.svg";
import { ReactComponent as ProteinIcon } from "../../assets/protein.svg";
import { ReactComponent as CaloriesIcon } from "../../assets/calories.svg";
import { useDispatch, useSelector } from "react-redux";
import { gettingAllCustomFoodOptions } from "../../features/diary/diarySlice";
import { selectCustomFoodOptions } from "../../features/diary/diarySelectors";
import {
  addCustomFoodItems,
  deleteCustomFoodItem,
  updateCustomFoodOrder
} from "../../api/diaryApi";
import useThemeColor from "../../utils/useTheme";
const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(2, 0)
}));

const StyledInputLabel = styled("label")(({ styledColors }) => ({
  position: "absolute",
  top: "-18px",
  color: styledColors.inputLabelColor,
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ styledColors }) => ({
  input: {
    color: styledColors.color
  },
  "& .MuiOutlinedInput-root": {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.modLabel
    },
    "&:hover fieldset": {
      borderColor: styledColors.modLabel
    },
    "&.Mui-focused fieldset": {
      color: styledColors.lightBlack,
      borderColor: styledColors.modLabel
    },
    "&.Mui-disabled": {
      background: styledColors.lightGreyColor,
      color: styledColors.color
    }
  }
}));
const CustomFoodManager = () => {
  const [open, setOpen] = useState(false);
  const allCustomFoodOptions = useSelector(selectCustomFoodOptions);
  const styles = useThemeColor();
  const [customFoodFormData, setCustomFoodFormData] = useState({
    name: "",
    quantity: "",
    noOfQty: "",
    unit: "grams",
    type: "800g",
    details: {
      serving: {
        serving_id: String(Math.floor(Math.random() * 90000) + 10000),
        metric_serving_amount: "",
        metric_serving_unit: "g",
        calories: "",
        carbohydrate: "",
        protein: "",
        fat: "",
        saturated_fat: "",
        sodium: "",
        potassium: "",
        fiber: "",
        vitamin_a: "",
        vitamin_c: "",
        vitamin_d: "",
        calcium: "",
        iron: ""
      }
    }
  });
  const [filterType, setFilterType] = useState("800g");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(gettingAllCustomFoodOptions(filterType));
  }, [filterType]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCustomFoodFormData({
      name: "",
      details: {
        serving: {
          serving_id: String(Math.floor(Math.random() * 90000) + 10000),
          metric_serving_amount: "",
          metric_serving_unit: "g",
          calories: "",
          carbohydrate: "",
          protein: "",
          fat: "",
          saturated_fat: "",
          sodium: "",
          potassium: "",
          fiber: "",
          vitamin_a: "",
          vitamin_c: "",
          vitamin_d: "",
          calcium: "",
          iron: ""
        }
      }
    });
  };

  const [customDisabledFields, setCustomDisabledFields] = useState({
    quantity: false,
    noOfQty: false,
    unit: false
  });

  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setCustomFoodFormData({
      ...customFoodFormData,
      [name]: value,
      ...(name === "quantity" && { noOfQty: "" }),
      ...(name === "noOfQty" && { quantity: "" })
    });
  };

  const handleCustomQuantityChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleCustomInputChange(e);
      setCustomDisabledFields({
        quantity: false,
        noOfQty: value === "" ? false : true
        // unit: value === "" ? false : true
      });
    }
  };

  const handleCustomNoOfQtyChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and ensure it's at least 0
    if (Number(value) >= 0) {
      handleCustomInputChange(e);
      setCustomDisabledFields({
        quantity: value === "" ? false : true,
        noOfQty: false,
        unit: value === "" ? false : true
      });
    }
  };

  const handleCustomFoodInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setCustomFoodFormData((prev) => ({
        ...prev,
        name: value
      }));
    } else {
      setCustomFoodFormData((prev) => ({
        ...prev,
        details: {
          ...prev.details,
          serving: {
            ...prev.details.serving,
            [name.split(".").pop()]: value
          }
        }
      }));
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const data = {
        isFromSuperAdmin: true,
        name: customFoodFormData?.name,
        quantity: customFoodFormData?.quantity,
        noOfQty: customFoodFormData?.noOfQty,
        unit: customFoodFormData?.unit,
        type: customFoodFormData?.type,
        details: {
          ...customFoodFormData?.details,
          serving: {
            ...Object.fromEntries(
              Object.entries(customFoodFormData?.details?.serving || {}).map(
                ([key, value]) => [key, value === "" ? 0 : value]
              )
            )
            // metric_serving_amount: customFoodFormData?.quantity
            //   ? Number(customFoodFormData.quantity)
            //   : customFoodFormData?.details?.serving?.metric_serving_amount || 0
          }
        },
        id: customFoodFormData?.id
      };
      await addCustomFoodItems(data);
      dispatch(gettingAllCustomFoodOptions(filterType));
      handleClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (food) => {
    setDraggedItem(food);
  };

  const handleDrop = async (targetFood) => {
    if (!draggedItem || draggedItem._id === targetFood._id) return;

    try {
      // Call the API to update the order
      await updateCustomFoodOrder({
        draggedItemId: draggedItem._id,
        targetItemId: targetFood._id
      });

      // Refresh the data after reordering
      dispatch(gettingAllCustomFoodOptions(filterType));

      // Reset drag state
      setDraggedItem(null);
    } catch (error) {
      console.error("Error reordering foods:", error?.message);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 800,
    maxHeight: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflow: "auto"
  };

  return (
    <Container maxWidth="100%">
      <Paper
        sx={{
          // p: 3,
          mb: 2,
          borderRadius: 2,
          boxShadow: "none"
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mb={3}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            sx={{
              textTransform: "none",
              borderRadius: 2,
              px: 3,
              background: (theme) => theme.palette.primary.main,
              "&:hover": {
                background: (theme) => theme.palette.primary.dark
              }
            }}
          >
            Create Food
          </Button>
        </Box>
        <StyledTextFieldContainer
          style={{ display: "flex", gap: "20px", marginTop: "40px" }}
        >
          <FormControl
            sx={{
              width: "20%",
              marginTop: "15px",
              svg: {
                fill: styles.color
              },
              ".MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: styles.modLabel
                },
                "&:hover fieldset": {
                  borderColor: styles.modLabel
                },
                "&.Mui-focused fieldset": {
                  borderColor: styles.disabledColor
                }
              }
            }}
          >
            <Select
              labelId="unit-label"
              name="type"
              value={filterType}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    padding: 0,
                    ".Mui-selected": {
                      color: `${styles.background} !important`,
                      background: `${styles.color} !important`
                    }
                  }
                }
              }}
              sx={{
                color: styles.color,
                marginTop: 0, // Remove any margin
                paddingTop: 0, // Remove any padding
                "& .Mui-disabled": {
                  background: styles.lightGreyColor,
                  color: `${styles.color} !important`
                },
                "& .MuiSelect-select": {
                  color: `${styles.color} !important`,
                  "-webkit-text-fill-color": `${styles.color} !important`
                }
              }}
              onChange={(e) => {
                const { name, value } = e.target;
                setFilterType(value);
              }}
            >
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="800g"
              >
                800g
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="protein"
              >
                Protein
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: styles.background,
                  color: styles.color,
                  paddingY: 1,
                  "&:hover": {
                    backgroundColor: styles.color,
                    color: styles.background
                  }
                }}
                value="other"
              >
                Other
              </MenuItem>
            </Select>
          </FormControl>
        </StyledTextFieldContainer>
        <List sx={{ bgcolor: "background.paper", borderRadius: 1 }}>
          {allCustomFoodOptions?.map((food, index) => (
            <React.Fragment key={food.id}>
              <ListItem
                key={food.id}
                draggable
                onDragStart={() => handleDragStart(food)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => handleDrop(food)}
                sx={{
                  transition: "all 0.2s",
                  cursor: "grab",
                  backgroundColor: (theme) =>
                    draggedItem?._id === food._id
                      ? alpha(theme.palette.primary.main, 0.08)
                      : "transparent",
                  "&:hover": {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04)
                  }
                }}
                secondaryAction={
                  <Box>
                    <Tooltip title="Edit food">
                      <IconButton
                        edge="end"
                        sx={{
                          mr: 1,
                          color: "primary.main",
                          "&:hover": {
                            bgcolor: (theme) =>
                              alpha(theme.palette.primary.main, 0.1)
                          }
                        }}
                        onClick={() => {
                          setCustomFoodFormData({
                            id: food?._id,
                            ...food
                          });
                          handleOpen();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete food">
                      <IconButton
                        edge="end"
                        sx={{
                          color: "error.main",
                          "&:hover": {
                            bgcolor: (theme) =>
                              alpha(theme.palette.error.main, 0.1)
                          }
                        }}
                        onClick={async () => {
                          await deleteCustomFoodItem(food?._id);
                          dispatch(gettingAllCustomFoodOptions(filterType));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" fontWeight={500}>
                      {food.name}
                    </Typography>
                  }
                  secondary={
                    <Box display="flex" gap={4} mt={0.5}>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <AppleIcon
                          style={{ color: "#F6541C", width: 30, height: 30 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {food.details.serving.metric_serving_amount}g
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <ProteinIcon
                          style={{ color: "#368AD0", width: 30, height: 30 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {food.details.serving.protein}g protein
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <CaloriesIcon
                          style={{ color: "#FF9431", width: 30, height: 30 }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {food.details.serving.calories} cal
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
              {index < allCustomFoodOptions?.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>

      {/* Modal Form */}
      <Modal
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="custom-food-modal"
      >
        <Box sx={modalStyle}>
          <Typography
            variant="h6"
            component="h2"
            mb={4}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <RestaurantIcon color="primary" />
            {customFoodFormData.id ? "Edit Food" : "Create New Food"}
          </Typography>

          <Box component="form">
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "40px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="quantity">
                Food Name*
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                // min={0}
                name="name"
                margin="normal"
                // htmlInput={{ min: 0 }}
                value={customFoodFormData.name}
                onChange={handleCustomFoodInputChange}
                required
                sx={{ width: { xs: "100%" }, mb: 4 }}
              />
            </StyledTextFieldContainer>
            <StyledTextFieldContainer style={{ display: "flex", gap: "20px" }}>
              <StyledInputLabel styledColors={styles} htmlFor="quantity">
                Amount
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                type="number"
                // min={0}
                name="quantity"
                slotProps={{
                  htmlInput: {
                    maxLength: 13,
                    step: "1"
                  }
                }}
                onWheel={(e) => e.target.blur()}
                margin="normal"
                // htmlInput={{ min: 0 }}
                placeholder="Enter the amount"
                value={customFoodFormData.quantity}
                onChange={handleCustomQuantityChange}
                disabled={customDisabledFields.quantity}
                sx={{ width: { xs: "100%", md: "60%" } }}
              />
              <FormControl
                sx={{
                  width: "40%",
                  marginTop: "15px",
                  svg: {
                    fill: styles.color
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: styles.color
                    },
                    "&:hover fieldset": {
                      borderColor: styles.color
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: styles.disabledColor
                    }
                  }
                }}
              >
                <Select
                  labelId="unit-label"
                  name="unit"
                  value={customFoodFormData.unit}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        padding: 0,
                        ".Mui-selected": {
                          color: `${styles.background} !important`,
                          background: `${styles.color} !important`
                        }
                      }
                    }
                  }}
                  sx={{
                    color: styles.color,
                    marginTop: 0, // Remove any margin
                    paddingTop: 0, // Remove any padding
                    "& .Mui-disabled": {
                      background: styles.lightGreyColor
                      // color: styles.color
                    }
                  }}
                  onChange={handleCustomInputChange}
                  disabled={customDisabledFields.unit}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="grams"
                  >
                    Grams
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="ounces"
                  >
                    Ounces
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="cups"
                  >
                    Cups / Fists
                  </MenuItem>
                  {[
                    { value: "palms", label: "Palms" },
                    { value: "pounds", label: "Pounds" }
                    // { value: "mls", label: " MLs (millimeters)" }
                  ]?.map(({ value, label }) => (
                    <MenuItem
                      sx={{
                        backgroundColor: styles.background,
                        color: styles.color,
                        paddingY: 1,
                        "&:hover": {
                          backgroundColor: styles.color,
                          color: styles.background
                        }
                      }}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledTextFieldContainer>
            <Typography
              display="flex"
              fontWeight="bold"
              justifyContent="center"
            >
              OR
            </Typography>
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "15px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="noOfQty">
                No. of Quantity
              </StyledInputLabel>
              <StyledTextField
                styledColors={styles}
                type="number"
                onWheel={(e) => e.target.blur()}
                // min={0}
                name="noOfQty"
                slotProps={{
                  htmlInput: {
                    maxLength: 13,
                    step: "1"
                  }
                }}
                margin="normal"
                // htmlInput={{ min: 0 }}
                placeholder="Enter the quantity"
                value={customFoodFormData.noOfQty}
                onChange={handleCustomNoOfQtyChange}
                disabled={customDisabledFields.noOfQty}
                sx={{ width: { xs: "100%" } }}
              />
            </StyledTextFieldContainer>
            <StyledTextFieldContainer
              style={{ display: "flex", gap: "20px", marginTop: "40px" }}
            >
              <StyledInputLabel styledColors={styles} htmlFor="type">
                Pillar Label
              </StyledInputLabel>
              <FormControl
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  svg: {
                    fill: styles.color
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: styles.modLabel
                    },
                    "&:hover fieldset": {
                      borderColor: styles.modLabel
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: styles.disabledColor
                    }
                  }
                }}
              >
                <Select
                  labelId="unit-label"
                  name="type"
                  value={customFoodFormData.type}
                  // disabled={editData?.name}
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        padding: 0,
                        ".Mui-selected": {
                          color: `${styles.background} !important`,
                          background: `${styles.color} !important`
                        }
                      }
                    }
                  }}
                  sx={{
                    color: styles.color,
                    marginTop: 0, // Remove any margin
                    paddingTop: 0, // Remove any padding
                    "& .Mui-disabled": {
                      background: styles.lightGreyColor,
                      color: `${styles.color} !important`
                    },
                    "& .MuiSelect-select": {
                      color: `${styles.color} !important`,
                      "-webkit-text-fill-color": `${styles.color} !important`
                    }
                  }}
                  onChange={(e) => {
                    handleCustomInputChange(e);
                  }}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="800g"
                  >
                    800g
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="protein"
                  >
                    Protein
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: styles.background,
                      color: styles.color,
                      paddingY: 1,
                      "&:hover": {
                        backgroundColor: styles.color,
                        color: styles.background
                      }
                    }}
                    value="other"
                  >
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            </StyledTextFieldContainer>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              Three Pillars Method
            </Typography>
            <Box display="flex" gap={2} mb={4}>
              {[
                {
                  label: "800g",
                  name: "metric_serving_amount",
                  icon: <AppleIcon />,
                  color: "#F6541C",
                  bgColor: "#FEECE6"
                },
                {
                  label: "Protein",
                  name: "protein",
                  icon: <ProteinIcon />,
                  color: "#368AD0",
                  bgColor: "#D4E9FC"
                },
                {
                  label: "Calories",
                  name: "calories",
                  icon: <CaloriesIcon />,
                  color: "#FF9431",
                  bgColor: "#FFF5E6"
                }
              ].map((field) => (
                <Paper
                  key={field.name}
                  sx={{
                    flex: 1,
                    p: 2,
                    bgcolor: field.bgColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 2,
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "translateY(-2px)"
                    }
                  }}
                >
                  <Box sx={{ color: field.color, mb: 1 }}>{field.icon}</Box>
                  <Typography
                    fontWeight={700}
                    variant="caption"
                    mb={1}
                    color="text.secondary"
                  >
                    {field.label}
                  </Typography>
                  <StyledTextField
                    styledColors={styles}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name={`details.serving.${field.name}`}
                    value={customFoodFormData.details.serving[field.name]}
                    onChange={handleCustomFoodInputChange}
                    sx={{
                      width: "120px",
                      "& .MuiOutlinedInput-input": {
                        textAlign: "center"
                      },
                      "& .MuiOutlinedInput-root": {
                        bgcolor: "white"
                      }
                    }}
                    inputProps={{ min: 0 }}
                  />
                </Paper>
              ))}
            </Box>

            <Typography variant="subtitle1" fontWeight={500} mt={4} mb={2}>
              Additional Stats
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
              gap={3}
            >
              {[
                { label: "Carbohydrate", name: "carbohydrate", unit: "g" },
                { label: "Fiber", name: "fiber", unit: "g" },
                { label: "Fat", name: "fat", unit: "g" },
                { label: "Saturated Fat", name: "saturated_fat", unit: "g" },
                { label: "Calcium", name: "calcium", unit: "mg" },
                { label: "Iron", name: "iron", unit: "mg" },
                { label: "Potassium", name: "potassium", unit: "mg" },
                { label: "Sodium", name: "sodium", unit: "mg" },
                { label: "Vitamin A", name: "vitamin_a", unit: "mcg" },
                { label: "Vitamin C", name: "vitamin_c", unit: "mg" },
                { label: "Vitamin D", name: "vitamin_d", unit: "mcg" }
              ].map((field) => (
                <Box
                  key={field.name}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                    bgcolor: "grey.50",
                    borderRadius: 1
                  }}
                >
                  <Typography
                    fontWeight={700}
                    variant="body2"
                    color="text.secondary"
                  >
                    {field.label}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={1}>
                    <StyledTextField
                      styledColors={styles}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name={`details.serving.${field.name}`}
                      value={customFoodFormData.details.serving[field.name]}
                      onChange={handleCustomFoodInputChange}
                      size="small"
                      sx={{
                        width: "120px",
                        "& .MuiOutlinedInput-input": {
                          textAlign: "center"
                        }
                      }}
                      inputProps={{ min: 0 }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {field.unit}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box display="flex" gap={2} mt={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleClose}
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                  py: 1.5
                }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  !customFoodFormData.name ||
                  (!customFoodFormData.quantity &&
                    !(
                      customFoodFormData.noOfQty &&
                      customFoodFormData.details.serving.metric_serving_amount
                    ))
                }
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                  py: 1.5
                }}
              >
                {customFoodFormData.id ? "Save Changes" : "Create Food"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default CustomFoodManager;
