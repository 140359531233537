import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Modal,
  IconButton,
  Button
} from "@mui/material";
import AllUsers from "../Tables/UserTable"; // Updated import
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllUsers,
  selectCurrentPage,
  selectLimit,
  selectTotalUsers,
  selectUsersError,
  selectUsersLoading
} from "../../features/users/userSelectors";
import {
  deleteUser,
  fetchAllUsers,
  setLimit,
  setPage
} from "../../features/users/userSlice";
import { Close } from "@mui/icons-material";
import { getCurrentUser } from "../../features/auth/authSlice";
import dayjs from "dayjs";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      textTransform={"none"}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

const UserTab = ({
  isAdmin,
  filters,
  onEdit,
  applyFilter,
  setApplyFilter,
  setEditData,
  openModal,
  searchValue
}) => {
  const [value, setValue] = useState(0);
  const [currentTab, setCurrentTab] = useState("All");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const users = useSelector(selectAllUsers); // Now fetched from Redux
  const totalUsers = useSelector(selectTotalUsers);
  const page = useSelector(selectCurrentPage);
  const limit = useSelector(selectLimit);
  const loading = useSelector(selectUsersLoading);
  const error = useSelector(selectUsersError);

  useEffect(() => {
    if (searchValue?.length > 3 || applyFilter) {
      dispatch(setPage(0));
      dispatch(setLimit(10));
    }

    if (!searchValue || searchValue?.length > 3 || applyFilter) {
      dispatch(
        fetchAllUsers({
          ...(currentTab !== "All" && { currentTab }),
          page,
          date: dayjs().format("YYYY-MM-DD"),
          limit,
          searchValue,
          ...((filters.gym ||
            filters.coach ||
            filters.company ||
            filters.affiliate) && {
            referenceId:
              filters.gym ||
              filters.coach ||
              filters.company ||
              filters.affiliate
          }),
          ...(filters.trainingLevel && {
            trainingLevelId: filters.trainingLevel
          }),
          ...(orderBy && {
            sortBy: orderBy,
            order
          })
        })
      );
      setApplyFilter(false);
    }
  }, [
    dispatch,
    page,
    limit,
    currentTab,
    openModal,
    searchValue,
    confirmDeleteModal,
    applyFilter,
    order,
    orderBy
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setPage(0));
    dispatch(setLimit(10));
    setCurrentTab(
      newValue === 0
        ? "All"
        : newValue === 1
        ? "Gym"
        : newValue === 2
        ? "Coach"
        : newValue === 3
        ? "Company"
        : "Affiliate"
    );
  };

  const handleDelete = async (hardDeleteNeeded = false) => {
    await dispatch(
      deleteUser({ userId: confirmDeleteModal?.userId, hardDeleteNeeded })
    );
    if (confirmDeleteModal?.isAdmin) await dispatch(getCurrentUser());
    setConfirmDeleteModal(null);
  };

  return (
    <Box mt={4}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        sx={{
          "& .MuiTab-root": { minWidth: "130px" },
          "& .MuiTabs-indicator": { background: "#1976d2" }
        }}
      >
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="All"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Gym"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Coach"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Company"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#1976d2" }
          }}
          label="Affiliate"
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AllUsers
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          totalUsers={totalUsers}
          users={users}
          loading={loading}
          error={error}
          onEdit={onEdit}
          currentTab={currentTab}
          setConfirmDeleteModal={setConfirmDeleteModal}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AllUsers
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          totalUsers={totalUsers}
          onEdit={onEdit}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
          setConfirmDeleteModal={setConfirmDeleteModal}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AllUsers
          totalUsers={totalUsers}
          setConfirmDeleteModal={setConfirmDeleteModal}
          onEdit={onEdit}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AllUsers
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          totalUsers={totalUsers}
          setConfirmDeleteModal={setConfirmDeleteModal}
          onEdit={onEdit}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AllUsers
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          totalUsers={totalUsers}
          setConfirmDeleteModal={setConfirmDeleteModal}
          onEdit={onEdit}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        open={!!confirmDeleteModal}
        onClose={() => setConfirmDeleteModal(null)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            width: { xs: "90%", sm: "550px" } // Responsive width
          }}
        >
          {/* Close button */}
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setConfirmDeleteModal(null)}>
              <Close />
            </IconButton>
          </Box>

          {/* Modal title */}
          <Box mb={3} px={2} textAlign="center">
            <Typography fontSize={"24px"} fontWeight={600}>
              Are you sure you want to delete?
            </Typography>
            <Typography fontSize={"14px"} color="text.secondary" mt={1}>
              This change cannot be undone and may impact users.
            </Typography>
          </Box>

          {/* Action buttons */}
          <Box display="flex" justifyContent="space-between" px={2} gap={2}>
            {/* Cancel Button */}
            <Button
              variant="outlined"
              onClick={() => setConfirmDeleteModal(null)}
              sx={{
                flex: 1,
                padding: "10px 0",
                borderColor: "grey.400",
                color: "grey.600",
                "&:hover": {
                  backgroundColor: "grey.100",
                  borderColor: "grey.500"
                }
              }}
            >
              Cancel
            </Button>

            {/* Delete Button */}
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete()} // Assuming you have this delete function
              sx={{
                flex: 1,
                padding: "10px 0",
                color: "white",
                background: "#E94545",
                "&:hover": {
                  backgroundColor: "error.dark"
                }
              }}
            >
              Delete
            </Button>
          </Box>
          {isAdmin ? (
            <Box
              display="flex"
              mt={2}
              justifyContent="space-between"
              px={2}
              gap={2}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(true)} // Assuming you have this delete function
                sx={{
                  flex: 1,
                  padding: "10px 0",
                  color: "white",
                  background: "#B83A3A",
                  "&:hover": {
                    backgroundColor: "error.dark"
                  }
                }}
              >
                Delete Forever
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default UserTab;
